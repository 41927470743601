import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./clientsStyle.scss";

import { Cards } from "../../Component/Common/Cards/Cards";
import { SearchAndFilterBar } from "../../Component/Common/SearchAndFilterBar/SearchAndFilterBar";
import { Table } from "../../Component/Common/Table/Table";

import { ClientTableMenu } from "../../Component/Clients/ClientTableMenu/ClientTableMenu";
import { AddClientModal } from "../../Component/Clients/AddClientModal/AddClientModal";
import { useNavigate } from "react-router-dom";
import { EditClientAddAddressModal } from "../../Component/Clients/EditClientAddAddressModal/EditClientAddAddressModal";
import { EditClientAddCreditcardModal } from "../../Component/Clients/EditClientAddCreditcardModal/EditClientAddCreditcardModal";
import { ClientMenu } from "./ClientMenu/ClientMenu";
import { CustDateRangePicker } from "../../Component/Custom/CustDateRangePicker/CustDateRangePicker";

// import client_table_menu_icon from '../../img/miscellaneous/client_table_menu.png'
import {
  Close,
  Eye,
  MenuDots,
  Receipt,
} from "../../Component/Common/Images/Images";
import add_icon from "../../img/miscellaneous/close_square_add.png";
import arrow_left from "../../img/miscellaneous/arrow_left.png";
import arrow_right from "../../img/miscellaneous/arrow_right.png";

import {
  checktoken,
  createorder,
  editclient,
  getclients,
  getclient_byid,
  getorder,
  getorderbyid,
  mobile_convert_display,
  set_archive,
} from "../../API/API";
import { AddOrderModal } from "../../Component/Orders/AddOrderModal/AddOrderModal";
import { Custgraph1 } from "../../Component/Custom/CustGraph/CustGraph1/Custgraph1";
import { FuturePredictions } from "../../Component/Clients/FuturePredictions/FuturePredictions";
import { ClientCards } from "../../Component/Common/Cards/ClientCards/ClientCards";
import { ClientFilter } from "../../Component/Clients/ClientFilter/ClientFilter";
import { ClientChargeNowModal } from "../../Component/Clients/ClientChargeNowModal/ClientChargeNowModal";
import {
  ClientCsv,
  ExportAll,
} from "../../Component/TableCsvConverter/TableToCsv";

const calc_day = (num) => {
  num = Number(num);
  if (num === 0) {
    return "Today";
  }
  if (num === 1) {
    return "Tomorrow";
  }
  if (num === 2) {
    return "Day After Tomorrow";
  }
  if (num >= 3 && num <= 5) {
    return num + " days";
  }

  // else if (num === 6) {
  //   return "Within a Week";
  // } else if (num >= 7 && num < 14) {
  //   return "Within a Week";
  // } else if (num >= 14 && num < 21) {
  //   return "Next Week";
  // } else if (num >= 21 && num < 28) {
  //   return "Next Week";
  // }
  if (num >= 0 && num < 7) {
    return "In a week";
  }
  if (num >= 7 * 1 && num < 7 * 2) {
    return "In 2 weeks";
  }
  if (num >= 7 * 2 && num < 7 * 3) {
    return "In 3 weeks";
  }
  if (num >= 7 * 3 && num < 7 * 4) {
    return "In 4 weeks";
  }
  if (num >= 7 * 4 && num < 7 * 5 - 5) {
    return "Within a month";
  }
  if (num >= 7 * 5 - 5 && num < 7 * 10 - 10) {
    return "In 2 months";
  }
  if (num >= 7 * 10 - 10 && num < 7 * 15 - 15) {
    return "In 3 months";
  }
  if (num >= 7 * 15 - 15 && num < 7 * 20 - 20) {
    return "In 4 months";
  }
  if (num >= 7 * 20 - 20 && num < 7 * 25 - 25) {
    return "In 5 months";
  }
  if (num >= 7 * 25 - 25 && num < 7 * 30 - 30) {
    return "In 6 months";
  }
  if (num >= 7 * 30 - 30 && num < 7 * 35 - 35) {
    return "In 7 months";
  }
  if (num >= 7 * 35 - 35 && num < 7 * 40 - 40) {
    return "In 8 months";
  }
  if (num >= 7 * 40 - 40 && num < 7 * 45 - 45) {
    return "In 9 months";
  }
  if (num >= 7 * 45 - 45 && num < 7 * 50 - 50) {
    return "In 10 months";
  }
  if (num >= 7 * 50 - 50 && num < 7 * 55 - 55) {
    return "In 11 months";
  }
  if (num >= 7 * 55 - 55 && num < 7 * 60 - 60) {
    return "Within a year";
  }
  return num + " days";
};
const calc_avg = (num) => {
  let temp_avg_data = 1 / num;
  if (temp_avg_data >= 0 && temp_avg_data < 7) {
    return "Once per week";
  }
  if (temp_avg_data >= 7 * 1 && temp_avg_data < 7 * 2) {
    return "Once every 2 weeks";
  }
  if (temp_avg_data >= 7 * 2 && temp_avg_data < 7 * 3) {
    return "Once every 3 weeks";
  }
  if (temp_avg_data >= 7 * 3 && temp_avg_data < 7 * 4) {
    return "Once every 4 weeks";
  }
  if (temp_avg_data >= 7 * 4 && temp_avg_data < 7 * 5 - 5) {
    return "Once per month";
  }
  if (temp_avg_data >= 7 * 5 - 5 && temp_avg_data < 7 * 10 - 10) {
    return "Once every 2 months";
  }
  if (temp_avg_data >= 7 * 10 - 10 && temp_avg_data < 7 * 15 - 15) {
    return "Once every 3 months";
  }
  if (temp_avg_data >= 7 * 15 - 15 && temp_avg_data < 7 * 20 - 20) {
    return "Once every 4 months";
  }
  if (temp_avg_data >= 7 * 20 - 20 && temp_avg_data < 7 * 25 - 25) {
    return "Once every 5 months";
  }
  if (temp_avg_data >= 7 * 25 - 25 && temp_avg_data < 7 * 30 - 30) {
    return "Once every 6 months";
  }
  if (temp_avg_data >= 7 * 30 - 30 && temp_avg_data < 7 * 35 - 35) {
    return "Once every 7 months";
  }
  if (temp_avg_data >= 7 * 35 - 35 && temp_avg_data < 7 * 40 - 40) {
    return "Once every 8 months";
  }
  if (temp_avg_data >= 7 * 40 - 40 && temp_avg_data < 7 * 45 - 45) {
    return "Once every 9 months";
  }
  if (temp_avg_data >= 7 * 45 - 45 && temp_avg_data < 7 * 50 - 50) {
    return "Once every 10 months";
  }
  if (temp_avg_data >= 7 * 50 - 50 && temp_avg_data < 7 * 55 - 55) {
    return "Once every 11 months";
  }
  if (temp_avg_data >= 7 * 55 - 55 && temp_avg_data < 7 * 60 - 60) {
    return "Once every year";
  }

  return "Once every 1+ year";
};

const order_statuses = [
  "All",
  "Pickup Requested",
  "Driver Enroute to Pickup",
  "Picked Up",
  "Customer Charged",
  "Deliver Clothes",
  "Driver Enroute to Dropoff",
  "Order Cancelled",
  "Order Completed",
];
// const img_arr = [
//   client1,
//   client2,
//   client3,
//   client4,
//   client5,
//   client6,
//   client7,
//   client8,
//   client9,
//   client1,
//   client2,
//   client3,
//   client4,
//   client5,
//   client6,
//   client7,
// ];
// const status = [
//   0  "waiting for pickup",
//   1  "ready for pickup",
//   2  ed up",
//   3  "in cleaning",
//   4  "out for delivery",
//   5  'delivered'
//       ];
// const status = [
//   "waiting for pickup",
//   "ready for pickup",
//   "picked up",
//   "in cleaning",
//   "out for delivery",
//   "delivered",
// ];
const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const Clients = ({
  setview_order,
  setshow_navbar,
  setdata,
  setloader,
  setcurrent_client_page_showing,
  current_client_page_showing,
}) => {
  const [clientchargenowmodal, setclientchargenowmodal] = useState(false);
  // const [confirm_archive_modal, setconfirm_archive_modal] = useState(false);
  const [add_order_modal, setadd_order_modal] = useState(false);
  // const [card_values, setcard_values] = useState({
  //   total_spent: 0,
  //   avg_retention_cycle: 0,
  //   total_order: 0,
  // });
  const [client_table_filter, setclient_table_filter] = useState({
    is_archived: 0,
  });
  const [client_filtermodal, setclient_filtermodal] = useState(false);

  const [to_show_client_table, setto_show_client_table] = useState([]);
  const [client_table_original, setclient_table_original] = useState([]);
  const [to_show_table_details, setto_show_table_details] = useState("default");
  useEffect(() => {
    setcurrent_client_page_showing(to_show_table_details);
  }, [to_show_table_details]);
  useEffect(() => {
    if (
      to_show_table_details !== "default" &&
      current_client_page_showing == "default"
    ) {
      setto_show_table_details("default");
    }
  }, [current_client_page_showing]);

  const [client_table_menu, setclient_table_menu] = useState("");
  const [address_arr, setaddress_arr] = useState(["test"]);
  const [address_arr_index, setaddress_arr_index] = useState(0);
  const [editclient_add_address_modal, seteditclient_add_address_modal] =
    useState(false);
  const [editclient_add_creditcard_modal, seteditclient_add_creditcard_modal] =
    useState(false);
  const [current_showing_client, setcurrent_showing_client] = useState([
    {},
    -1,
  ]);
  const [current_showing_client_data, setcurrent_showing_client_data] =
    useState({});
  const [temp_address_arr, settemp_address_arr] = useState(["", "", ""]);
  const [addclientmodal, setaddclientmodal] = useState(false);
  const [temp_data, settemp_data] = useState({});
  const [temp_ccdatamodal_arr, settemp_ccdatamodal_arr] = useState([
    "",
    "",
    "",
  ]);
  // const [cc_arr, setcc_arr] = useState([])
  const [cc_arr_index, setcc_arr_index] = useState(0);
  const [client_menu, setclient_menu] = useState("view");
  const [analytics_date, setanalytics_date] = useState([
    new Date(),
    // new Date()
    //   .toString()
    //   .split(" ")
    //   .slice(1, 4)
    //   .map((el, i) => (i === 1 ? 1 : el))
    //   .join(" ")
    new Date(),
    // new Date().toString().split(" ").slice(1, 4).join(" ") + " 23:59:59"
  ]);
  const [analytics_data, setanalytics_data] = useState({
    og_arr: [],
    filterd_arr: [],
    total_amount: 0,
    total_order: 0,
    total_items: 0,
  });
  const [client_cards, setclient_cards] = useState([0, 0, [0, 0, 0], 0, 0, 0]);
  useEffect(() => {
    if (current_showing_client_data.orders) {
      let temp_date = [
        new Date(analytics_date[0].toString().split(" ").slice(1, 4).join(" ")),
        new Date(
          analytics_date[1].toString().split(" ").slice(1, 4).join(" ") +
            " 23:59:59"
        ),
      ];
      let temp_total_amount = 0;
      let temp_arr = current_showing_client_data.orders.filter((el) => {
        temp_total_amount += el.amount ? Number(el.amount) : 0;
        return (
          new Date(el.order_date).valueOf() >= temp_date[0].valueOf() &&
          new Date(el.order_date).valueOf() <= temp_date[1].valueOf()
        );
      });
      let total_amount = 0;
      temp_arr.map((el) => (total_amount += el.amount ? Number(el.amount) : 0));

      setanalytics_data((prev) => ({
        ...prev,
        // filterd_arr: temp_arr,
        // filterd_arr: temp_arr,
        total_order: temp_arr.length,
        total_amount: total_amount,
      }));
      setclient_cards((prev) => [
        prev[0],
        prev[1],
        prev[2],
        temp_total_amount.toFixed(2),
        0,
        current_showing_client_data.orders.length,
      ]);
    }
  }, [current_showing_client_data, analytics_date]);
  useEffect(() => {
    if (current_showing_client_data.orders) {
      if (analytics_date[0] && analytics_date[1]) {
        let temp_date = [
          new Date(
            analytics_date[0].toString().split(" ").slice(1, 4).join(" ")
          ),
          new Date(
            analytics_date[1].toString().split(" ").slice(1, 4).join(" ") +
              " 23:59:59"
          ),
        ];
        let temp_arr = current_showing_client_data.orders.filter((el) => {
          return (
            new Date(el.order_date).valueOf() >= temp_date[0].valueOf() &&
            new Date(el.order_date).valueOf() <= temp_date[1].valueOf()
          );
        });
        // .map(
        //   (el) => `${new Date(el.toString().split(" ").slice(1, 4).join(" "))}`
        // );
        setanalytics_data((prev) => ({
          ...prev,
          filterd_arr: temp_arr,
          og_arr: current_showing_client_data.orders,
          // total_order: temp_arr.length,
          // total_amount: temp_arr.reduce(
          //   (prev, curr) => (curr.amount ? (prev += Number(curr.amount)) : ""),
          //   0
          //   ),
        }));
      }
    }
  }, [analytics_date, current_showing_client_data]);

  // const [order_history_search, setorder_history_search] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const current_store = useSelector((state) => state);

  useEffect(() => {
    settemp_data({
      ...current_showing_client_data,
    });
    // setcc_arr((current_showing_client[0].payment) ? [current_showing_client[0].payment] : [])
    setaddress_arr_index(0);
    setcc_arr_index(0);
  }, [current_showing_client]);
  useEffect(() => {
    if (
      (to_show_table_details == "view" || to_show_table_details == "edit") &&
      (client_menu == "view" ||
        client_menu == "orderhistory" ||
        client_menu == "analytics" ||
        client_menu == "futureprediction") &&
      //  ||
      // client_menu == "futureprediction"
      // (client_menu == "view" || client_menu == "analytics") &&
      current_showing_client[1] != -1
    ) {
      // setclient_table_filter({
      //   is_archived: 0,
      //   date: "",
      // });
      setloader(true);
      getclient_byid(
        current_showing_client[0].id,
        current_store.user.token,
        (data) => {
          setloader(false);
          if (!data.status) {
            return navigate("/404");
          }
          setcurrent_showing_client_data({
            id: data.data.id,
            img:
              data.data.profile_photo == null ||
              data.data.profile_photo ==
                "https://julietteimages.s3.amazonaws.com/profileimages/default.png"
                ? "https://julietteimages.s3.amazonaws.com/profileimages/default.png?1656920271059"
                : data.data.profile_photo,
            name: data.data.name,
            phone: data.data.mobile,
            payment: data.data.last_4_card || "",
            last_order: data.data.last_order || "",
            email: data.data.email,
            notes: data.data.notes,
            address: data.data.address,
            cards: data.data.cards,
            orders: data.data.orders,
            created_at: data.data.created_at,
            pay_later_amount: data.data.pay_later_amount || 0,
            paidTotal: data.data.paidTotal || 0,
          });
          settemp_data({
            id: data.data.id,
            img:
              data.data.profile_photo == null
                ? "https://julietteimages.s3.amazonaws.com/profileimages/default.png?1656920271059"
                : data.data.profile_photo,
            name: data.data.name,
            phone: data.data.mobile,
            payment: data.data.last_4_card || "",
            last_order: data.data.last_order || "",
            email: data.data.email,
            notes: data.data.notes,
            address: data.data.address,
            cards: data.data.cards,
            created_at: data.data.created_at,
          });
        }
      );
    }
  }, [to_show_table_details, client_menu, current_showing_client]);

  useEffect(() => {
    setshow_navbar(true);
  }, []);
  useEffect(() => {
    setloader(true);
    if (current_store.user.token == "") {
      let local_token = localStorage.getItem("jc_pos_token");
      if (local_token) {
        checktoken(local_token, (data) => {
          if (!data.status) {
            return navigate("/");
          }
          dispatch({
            type: "login",
            payload: { token: local_token, admin: data.admin },
          });
        });
        return;
      } else {
        return navigate("/");
      }
    } else {
      getclients(current_store.user.token, (data) => {
        setloader(false);
        if (!data.status) {
          return navigate("/");
        }
        setto_show_client_table(
          [
            ...data.data.map((el) => {
              return {
                id: el.id,
                img:
                  el.profile_photo_link == null ||
                  el.profile_photo_link ==
                    "https://julietteimages.s3.amazonaws.com/profileimages/default.png"
                    ? "https://julietteimages.s3.amazonaws.com/profileimages/default.png?1656920271059"
                    : el.profile_photo_link,
                name: el.full_name,
                phone: el.mobile,
                payment: el.cc_last_4_digits
                  ? (el.cc_last_4_digits.toString().length == 3
                      ? "0"
                      : el.cc_last_4_digits.toString().length == 2
                      ? "00"
                      : el.cc_last_4_digits.toString().length == 1
                      ? "000"
                      : "") + el.cc_last_4_digits
                  : "",
                last_order: el.order_date ? el.order_date.split("T")[0] : "",
                email: el.email,
                address: el.address,
                apt: el.apt,
                zipcode: el.zipcode,
                archived: el?.archived ? Number(el.archived) : 0,
                created_at: `${
                  new Date(el.created_at).getDate() < 10 ? "0" : ""
                }${new Date(el.created_at).getDate().toString()} ${month[
                  new Date(el.created_at).getMonth()
                ].toString()} ${new Date(el.created_at)
                  .getFullYear()
                  .toString()}`,
              };
            }),
          ]
          // .slice(0, 10)
        );
        setclient_table_original([
          ...data.data.map((el) => {
            return {
              id: el.id,
              img:
                el.profile_photo_link == null ||
                el.profile_photo_link ==
                  "https://julietteimages.s3.amazonaws.com/profileimages/default.png"
                  ? "https://julietteimages.s3.amazonaws.com/profileimages/default.png?1656920271059"
                  : el.profile_photo_link,
              name: el.full_name,
              phone: el.mobile,
              payment: el.cc_last_4_digits
                ? (el.cc_last_4_digits.toString().length == 3
                    ? "0"
                    : el.cc_last_4_digits.toString().length == 2
                    ? "00"
                    : el.cc_last_4_digits.toString().length == 1
                    ? "000"
                    : "") + el.cc_last_4_digits
                : "",
              last_order: el.order_date ? el.order_date.split("T")[0] : "",
              email: el.email,
              address: el.address,
              apt: el.apt,
              zipcode: el.zipcode,
              archived: el?.archived ? Number(el.archived) : 0,
              created_at: `${
                new Date(el.created_at).getDate() < 10 ? "0" : ""
              }${new Date(el.created_at).getDate().toString()} ${month[
                new Date(el.created_at).getMonth()
              ].toString()} ${new Date(el.created_at)
                .getFullYear()
                .toString()}`,
            };
          }),
        ]);
      });
      getorder(current_store.user.token, (data) => {
        let filtered_data = data.orders.filter(
          (el) =>
            [
              // "Order Cancelled",
              //  "Order Completed"
            ].indexOf(el.display_status) == -1
        );
        function monthDiff(d1, d2) {
          var months;
          months = (d2.getFullYear() - d1.getFullYear()) * 12;
          months -= d1.getMonth();
          months += d2.getMonth();
          return months <= 0 ? 0 : months;
        }
        let temp_total_orders = filtered_data.length;
        let order_dates = [
          new Date(filtered_data[0].order_date),
          new Date(filtered_data[temp_total_orders - 1].order_date),
        ];
        let date_diff = {
          // years: order_dates[0].getFullYear() - order_dates[1].getFullYear(),
          // month: monthDiff(order_dates[1], order_dates[0]),
          // days: parseInt(
          //   (order_dates[0].valueOf() - order_dates[1].valueOf()) /
          //     (24 * 60 * 60 * 1000)
          // ),
          days: Object.keys(
            filtered_data.reduce(
              (prev, curr) => ({ ...prev, [curr.order_date]: 0 }),
              {}
            )
          ).length,
          month:
            Object.keys(
              filtered_data.reduce(
                (prev, curr) => ({ ...prev, [curr.order_date]: 0 }),
                {}
              )
            ).length / 30,
        };
        console.log(date_diff);
        let temp_total_amount = 0;
        let client_this_month_orders = [];
        let client_this_quarter_orders = [];
        let client_this_year_orders = [];
        let client_order_last_day = {};
        let client_order_last_month = {};
        let client_order_last_quaterly = {};
        let client_order_last_year = {};
        let client_order_last_month_prev = {};
        let client_order_last_quaterly_prev = {};
        let client_order_last_year_prev = {};
        // change current_date
        // let temp_current_date = new Date("12/20/2022");
        let temp_current_date = new Date();
        let temp_last_month_date = new Date(
          `${temp_current_date.getMonth()}/${temp_current_date.getDate()}/${temp_current_date.getFullYear()}`
        );
        let temp_last_quarter_date = new Date(
          `${
            temp_current_date.getMonth() - 2
          }/${temp_current_date.getDate()}/${temp_current_date.getFullYear()}`
        );
        let temp_last_year_date = new Date(
          `${
            temp_current_date.getMonth() + 1
          }/${temp_current_date.getDate()}/${temp_current_date.getFullYear()}`
        );
        filtered_data.map((el) => {
          temp_total_amount =
            temp_total_amount + (el.amount ? Number(el.amount) : 0);
          let temp_order_date = new Date(el.order_date);
          // console.log(
          //   temp_order_date,
          //   temp_current_date.valueOf(),
          //   temp_order_date.valueOf(),
          //   temp_current_date.valueOf() - temp_order_date.valueOf() <= 0
          // );
          // console.log(
          //   el,
          //   temp_current_date.getFullYear(),
          //   temp_order_date.getFullYear(),
          //   temp_current_date.getMonth(),
          //   temp_order_date.getMonth
          // );
          if (
            temp_current_date.getFullYear() === temp_order_date.getFullYear() &&
            temp_current_date.getMonth() === temp_order_date.getMonth()
          ) {
            client_this_month_orders.push(el);
          }
          // if (
          //   temp_current_date.getFullYear() === temp_order_date.getFullYear() &&
          //   temp_current_date.getMonth() === temp_order_date.getMonth()
          // ) {
          //   // console.log(el);
          //   // client_this_quarter_orders.push(el);
          // }
          // if (
          //   temp_current_date.getFullYear() === temp_order_date.getFullYear() &&
          //   temp_current_date.getMonth() === temp_order_date.getMonth
          // ) {
          //   // console.log(el);
          //   // client_this_year_orders.push(el);
          // }
          if (temp_current_date.valueOf() - temp_order_date.valueOf() <= 0) {
            // console.log(el);
            if (client_order_last_day[el.client_name]) {
              client_order_last_day[el.client_name] += 1;
            } else {
              client_order_last_day[el.client_name] = 1;
            }
          }
          if (
            temp_current_date.valueOf() - temp_order_date.valueOf() <=
            31 * 24 * 60 * 60 * 1000
          ) {
            // console.log(el);
            if (client_order_last_month[el.client_name]) {
              client_order_last_month[el.client_name] += 1;
            } else {
              client_order_last_month[el.client_name] = 1;
            }
          }
          if (
            temp_current_date.valueOf() -
              31 * 24 * 60 * 60 * 1000 -
              temp_order_date.valueOf() <=
              31 * 24 * 60 * 60 * 1000 &&
            temp_current_date.valueOf() -
              31 * 24 * 60 * 60 * 1000 -
              temp_order_date.valueOf() >=
              0
          ) {
            // console.log(
            //   "last_last_month",
            //   el,
            //   new Date(temp_current_date.valueOf() - 31 * 24 * 60 * 60 * 1000),
            //   temp_order_date
            // );
            if (client_order_last_month_prev[el.client_name]) {
              client_order_last_month_prev[el.client_name] += 1;
            } else {
              client_order_last_month_prev[el.client_name] = 1;
            }
          }
          if (
            temp_current_date.valueOf() - temp_order_date.valueOf() <=
            3 * 31 * 24 * 60 * 60 * 1000
          ) {
            if (client_order_last_quaterly[el.client_name]) {
              client_order_last_quaterly[el.client_name] += 1;
            } else {
              client_order_last_quaterly[el.client_name] = 1;
            }
          }
          if (
            temp_current_date.valueOf() -
              3 * 31 * 24 * 60 * 60 * 1000 -
              temp_order_date.valueOf() <=
              3 * 31 * 24 * 60 * 60 * 1000 &&
            temp_current_date.valueOf() -
              3 * 31 * 24 * 60 * 60 * 1000 -
              temp_order_date.valueOf() >=
              0
          ) {
            if (client_order_last_quaterly_prev[el.client_name]) {
              client_order_last_quaterly_prev[el.client_name] += 1;
            } else {
              client_order_last_quaterly_prev[el.client_name] = 1;
            }
          }
          if (
            temp_current_date.valueOf() - temp_order_date.valueOf() <=
            365 * 24 * 60 * 60 * 1000
          ) {
            if (client_order_last_year[el.client_name]) {
              client_order_last_year[el.client_name] += 1;
            } else {
              client_order_last_year[el.client_name] = 1;
            }
          }
          if (
            temp_current_date.valueOf() -
              365 * 24 * 60 * 60 * 1000 -
              temp_order_date.valueOf() <=
              365 * 24 * 60 * 60 * 1000 &&
            temp_current_date.valueOf() -
              365 * 24 * 60 * 60 * 1000 -
              temp_order_date.valueOf() >=
              0
          ) {
            if (client_order_last_year_prev[el.client_name]) {
              client_order_last_year_prev[el.client_name] += 1;
            } else {
              client_order_last_year_prev[el.client_name] = 1;
            }
          }
        });
        // here
        let current_month_orders_sales =
          // data.orders
          filtered_data
            .filter(
              (el) =>
                new Date(el.order_date).getFullYear() ==
                  new Date(el.order_date).getFullYear() &&
                new Date(el.order_date).getMonth() == new Date().getMonth()
            )
            .reduce(
              (counter, el) => (counter += el.amount ? Number(el.amount) : 0),
              0
            );
        let last_month_orders_sales =
          // data.orders
          filtered_data.filter(
            (el) =>
              new Date(el.order_date).getFullYear() ==
                new Date(el.order_date).getFullYear() &&
              new Date(el.order_date).getMonth() ==
                (new Date().getMonth() == 0 ? 12 : new Date().getMonth() - 1)
          );
        // console.log(last_month_orders_sales);
        last_month_orders_sales = last_month_orders_sales.reduce(
          (counter, el) => (counter += el.amount ? Number(el.amount) : 0),
          0
        );
        // console.table(
        //   filtered_data.map((el) => ({
        //     name: el.client_name,
        //     date: el.order_date,
        //   }))
        // );

        // console.log(
        //   client_order_last_month,
        //   client_order_last_quaterly,
        //   client_order_last_year
        //   // temp_current_date
        // );
        // console.log("temp_current_date", temp_current_date);
        // console.log("temp_last_month_date", temp_last_month_date);
        // console.log("temp_last_quarter_date", temp_last_quarter_date);
        // console.log("temp_last_year_date", temp_last_year_date);
        const [
          retention_rate_day_og,
          retention_rate_monthly_og,
          retention_rate_quaterly_og,
          retention_rate_year_og,
          retention_rate_monthly,
          retention_rate_quaterly,
          retention_rate_year,
          retention_rate_monthly_prev,
          retention_rate_quaterly_prev,
          retention_rate_year_prev,
        ] = [
          // client_order_last_day.
          Object.values(client_order_last_day).length,
          // client_order_last_day,
          Object.values(client_order_last_month).length,
          Object.values(client_order_last_quaterly).length,
          Object.values(client_order_last_year).length,
          Object.values(client_order_last_month).filter((el) => el > 1).length,
          Object.values(client_order_last_quaterly).filter((el) => el > 1)
            .length,
          Object.values(client_order_last_year).filter((el) => el > 1).length,
          Object.values(client_order_last_month_prev).filter((el) => el > 1)
            .length,
          Object.values(client_order_last_quaterly_prev).filter((el) => el > 1)
            .length,
          Object.values(client_order_last_year_prev).filter((el) => el > 1)
            .length,
          ,
        ];
        // console.log(client_this_month_orders.length - retention_rate_monthly);
        // console.log({
        //   retention_rate_day_og,
        //   retention_rate_monthly_og,
        //   retention_rate_quaterly_og,
        //   retention_rate_year_og,
        //   retention_rate_monthly,
        //   retention_rate_quaterly,
        //   retention_rate_year,
        //   retention_rate_monthly_prev,
        //   retention_rate_quaterly_prev,
        //   retention_rate_year_prev,
        // });
        // const calc_retention_predection = (curr, prev) => {
        //   console.log(filtered_data.length, prev, curr);
        //   return Math.ceil(filtered_data.length * (prev / curr));
        // };
        // function myFunction() {
        var current_sales = current_month_orders_sales.toFixed(2);
        var date = new Date();
        date.setMonth(new Date().getMonth() + 1);
        date.setDate(0);
        var ynew = (current_sales / new Date().getDate()) * date.getDate();
        //   console.log(y)
        // }
        // let ynew =
        //   (current_month_orders_sales.toFixed(2) / new Date().getDate()) *
        //   new Date()
        //     .setMonth(new Date().getMonth() + 1)
        //     .setDate(0)
        //     .getDate();
        // console.log("ynew", ynew.toFixed(0));
        let temp_client_cards = [
          // (temp_total_amount / temp_total_orders).toFixed(2),
          [
            current_month_orders_sales.toFixed(2),
            last_month_orders_sales < ynew
              ? Math.ceil(ynew).toFixed(0)
              : last_month_orders_sales.toFixed(0),
          ],
          [
            [
              // retention_rate_monthly,
              (
                (retention_rate_monthly / retention_rate_monthly_og) *
                100
              ).toFixed(2) + "%",
              // retention_rate_monthly_prev &&
              //   calc_retention_predection(
              //     retention_rate_monthly,
              //     retention_rate_monthly_prev
              //   ),
              retention_rate_monthly - client_this_month_orders.length <= 0
                ? 0
                : retention_rate_monthly - client_this_month_orders.length,
            ],
            [
              // retention_rate_quaterly,
              (
                (retention_rate_quaterly / retention_rate_quaterly_og) *
                100
              ).toFixed(2) + "%",
              // retention_rate_quaterly_prev &&
              //   calc_retention_predection(
              //     retention_rate_quaterly,
              //     retention_rate_quaterly_prev
              //   ),
              retention_rate_quaterly - client_this_month_orders.length <= 0
                ? 0
                : retention_rate_quaterly - client_this_month_orders.length,
            ],
            [
              // retention_rate_year,
              ((retention_rate_year / retention_rate_year_og) * 100).toFixed(
                2
              ) + "%",
              // retention_rate_year_prev &&
              //   calc_retention_predection(
              //     retention_rate_year,
              //     retention_rate_year_prev
              //   ),
              retention_rate_year - client_this_month_orders.length <= 0
                ? 0
                : retention_rate_year - client_this_month_orders.length,
            ],
          ],
          [
            [
              date_diff.days === 0
                ? 0
                : (temp_total_orders / date_diff.days).toFixed(2),
              Math.ceil(temp_total_orders / date_diff.days) -
                retention_rate_day_og >
              0
                ? Math.ceil(temp_total_orders / date_diff.days) -
                  retention_rate_day_og
                : 0,
            ],
            [
              date_diff.days === 0
                ? 0
                : ((temp_total_orders / date_diff.days) * 7).toFixed(2),
              Math.ceil((temp_total_orders / date_diff.days) * 7) -
                retention_rate_day_og * 7 >
              0
                ? Math.ceil((temp_total_orders / date_diff.days) * 7) -
                  retention_rate_day_og * 7
                : 0,
            ],
            [
              date_diff.month === 0
                ? 0
                : (temp_total_orders / date_diff.month).toFixed(2),
              Math.ceil(temp_total_orders / date_diff.month) -
                retention_rate_monthly_og >
              0
                ? Math.ceil(temp_total_orders / date_diff.month) -
                  retention_rate_monthly_og
                : 0,
            ],
          ],
        ];
        setclient_cards([...temp_client_cards]);
      });
      // return
    }
    // setshow_navbar(true)
  }, [current_store.user.token]);
  const handle_client_table_search = (txt) => {
    setto_show_client_table([
      ...client_table_original.filter((el) => {
        return (
          el.name.toLowerCase().match(txt.toLowerCase()) ||
          el.payment.toString().match(txt.toLowerCase()) ||
          el.phone.toString().match(txt.toLowerCase()) ||
          el.email.toLowerCase().match(txt.toLowerCase())
        );
      }),
    ]);
  };
  const [order_list, setorder_list] = useState([[], []]);
  const [values, setvalues] = useState({
    latest_order: 0,
    total_days: 0,
    year_avg: 0,
    month_avg: {
      month_6: 0,
      month_4: 0,
      month_3: 0,
      month_2: 0,
      month_1: 0,
    },
    monthly_avg: 0,
    week_avg: {
      weeek_2: 0,
      weeek_1: 0,
    },
    weekly_avg: 0,
    daily_avg: 0,
  });
  const [latest_values, setlatest_values] = useState({
    latest_order: 0,
    total_days: 0,
    year_avg: 0,
    month_avg: {
      month_6: 0,
      month_4: 0,
      month_3: 0,
      month_2: 0,
      month_1: 0,
    },
    monthly_avg: 0,
    week_avg: {
      weeek_2: 0,
      weeek_1: 0,
    },
    weekly_avg: 0,
    daily_avg: 0,
  });
  useEffect(() => {
    if (
      current_showing_client_data.orders &&
      current_showing_client_data.orders.length > 0
    ) {
      let daily_avg = 0;
      let temp_og_arr = current_showing_client_data.orders.sort((a, b) => {
        return (
          new Date(a.order_date).valueOf() - new Date(b.order_date).valueOf()
        );
      });
      let temp_latest_arr = [...temp_og_arr].splice(-5);
      setorder_list([[...temp_og_arr], [...temp_latest_arr]]);

      let temp_values = {
        total_days: (
          (new Date().getTime() -
            new Date(temp_og_arr[0].order_date).getTime()) /
          (1000 * 3600 * 24)
        ).toFixed(0),
        latest_order:
          temp_og_arr[temp_og_arr.length - 1].order_date.split("T")[0],
      };

      temp_values = {
        ...temp_values,
        daily_avg: temp_og_arr.length / temp_values.total_days,
      };
      setvalues(() => ({
        latest_order: 0,
        total_days: 0,
        year_avg: 0,
        month_avg: {
          month_6: 0,
          month_4: 0,
          month_3: 0,
          month_2: 0,
          month_1: 0,
        },
        monthly_avg: 0,
        week_avg: {
          weeek_2: 0,
          weeek_1: 0,
        },
        weekly_avg: 0,
        daily_avg: 0,
        ...temp_values,
        estimated_day_left_for_future_order: Math.ceil(
          1 / Number(temp_values.daily_avg)
        ),
      }));

      let temp_latest_values = {
        total_days: (
          (new Date().getTime() -
            new Date(temp_latest_arr[0].order_date).getTime()) /
          (1000 * 3600 * 24)
        ).toFixed(0),
        latest_order:
          temp_latest_arr[temp_latest_arr.length - 1].order_date.split("T")[0],
      };
      temp_latest_values = {
        ...temp_latest_values,
        daily_avg: temp_latest_arr.length / temp_latest_values.total_days,
      };
      setlatest_values((prev) => ({
        latest_order: 0,
        total_days: 0,
        year_avg: 0,
        month_avg: {
          month_6: 0,
          month_4: 0,
          month_3: 0,
          month_2: 0,
          month_1: 0,
        },
        monthly_avg: 0,
        week_avg: {
          weeek_2: 0,
          weeek_1: 0,
        },
        weekly_avg: 0,
        daily_avg: 0,
        ...temp_latest_values,
        estimated_day_left_for_future_order: Math.round(
          1 / Number(temp_latest_values.daily_avg)
        ),
      }));
      // setTimeout(() => {
      setclient_cards((prev) => [
        prev[0],
        prev[1],
        prev[2],
        prev[3],
        calc_avg(temp_og_arr.length / temp_values.total_days)
          .replace("per", "/")
          .replace("Once every", "")
          .trim(),
        prev[5],
      ]);
      // console.log(current_showing_client_data, calc_avg(values.daily_avg));
      // }, 100);
    } else {
      setvalues({
        latest_order: 0,
        total_days: 0,
        year_avg: 0,
        month_avg: {
          month_6: 0,
          month_4: 0,
          month_3: 0,
          month_2: 0,
          month_1: 0,
        },
        monthly_avg: 0,
        week_avg: {
          weeek_2: 0,
          weeek_1: 0,
        },
        weekly_avg: 0,
        daily_avg: 0,
      });
      setlatest_values({
        latest_order: 0,
        total_days: 0,
        year_avg: 0,
        month_avg: {
          month_6: 0,
          month_4: 0,
          month_3: 0,
          month_2: 0,
          month_1: 0,
        },
        monthly_avg: 0,
        week_avg: {
          weeek_2: 0,
          weeek_1: 0,
        },
        weekly_avg: 0,
        daily_avg: 0,
      });
    }
  }, [current_showing_client_data]);
  // console.log(
  //   calc_avg(values.daily_avg),
  //   calc_day(values.estimated_day_left_for_future_order),
  //   calc_avg(latest_values.daily_avg),
  //   calc_day(latest_values.estimated_day_left_for_future_order)
  // );
  return (
    <div className="clients">
      <div className="pagetitle">
        <div>
          Clients
          <div
            className="orders_download"
            onClick={() => {
              ExportAll(
                [
                  ...(to_show_client_table ||
                    []
                      .filter((el) => {
                        if (Object.keys(el).indexOf("archived") == -1) {
                          return true;
                        }
                        if (client_table_filter.is_archived === 1) {
                          return el?.archived && el.archived === 1;
                        } else {
                          return el.archived === 0;
                        }
                      })
                      .filter((el) => {
                        if (
                          client_table_filter.date &&
                          client_table_filter.date !== ""
                        ) {
                          return (
                            new Date(el.created_at) -
                              new Date(client_table_filter.date) ===
                            0
                          );
                        }
                        return true;
                      })),
                ].map((el2) =>
                  ClientCsv({
                    data: {
                      ...el2,
                    },
                  })
                ),
                "Clients"
              );
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <path
                d="M11.5425 9.23571L18.8885 1.88965"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.6053 5.47397V1.17383H15.3052"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.75099 1.17383H7.95927C3.47996 1.17383 1.68823 2.96555 1.68823 7.44486V12.82C1.68823 17.2993 3.47996 19.0911 7.95927 19.0911H13.3344C17.8137 19.0911 19.6055 17.2993 19.6055 12.82V11.0283"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      {to_show_table_details == "default" ? (
        <ClientCards
          card_arr={[
            {
              title: `${
                month[new Date().getMonth()]
              } ${new Date().getFullYear()} Total Sales`,
              value: client_cards[0] ? client_cards[0] : ["-", "-"],
            },
            {
              title: "Retention Rate",
              value: client_cards[1] ? client_cards[1] : ["-", "-", "-"],
              divide: 3,
              options: ["Monthly", "Quaterly", "Year"],
            },
            {
              title: "AVG. Order",
              value: client_cards[2] ? client_cards[2] : ["-", "-", "-"],
              divide: 3,
              options: ["Day", "Weeks", "Month"],
            },
          ]}
          last_card={{
            title: "Client",
            click: () => {
              setaddclientmodal(true);
            },
          }}
        />
      ) : (
        <Cards
          card_arr={[
            {
              title: "Total Spent",
              value: client_cards[3] == 0 ? "-" : `$${client_cards[3]}`,
            },
            {
              title: "AVG. Retention Cycle",
              value: client_cards[4] || "-",
            },
            {
              title: "Total Order",
              value: client_cards[5] == 0 ? "-" : client_cards[5],
            },
          ]}
          last_card={{
            title: "Order",
            click: () => {
              setadd_order_modal(true);
            },
          }}
        />
      )}
      <div className="clients_table">
        <SearchAndFilterBar
          handleclick_filter={() => {
            setclient_filtermodal(true);
          }}
          placeholder="Search for Number, Date, Name, Mail,..."
          // filter_show="no"
          handlechange_search={(data) => {
            handle_client_table_search(data);
          }}
        />
        {client_filtermodal && (
          <ClientFilter
            close={() => setclient_filtermodal(false)}
            setclient_table_filter={setclient_table_filter}
            client_table_filter={client_table_filter}
          />
        )}
        <div className="client_filter_group">
          {client_table_filter.is_archived == 1 && (
            <div className="filter_cards">
              Archived
              <Close
                style={{
                  "--svg_current_color": "#fff",
                  width: 12,
                  height: 12,
                }}
                clickhandel={() => {
                  setclient_table_filter((prev) => ({
                    ...prev,
                    is_archived: 0,
                  }));
                }}
              />
            </div>
          )}
          {client_table_filter.date && (
            <div className="filter_cards">
              {client_table_filter.date}
              <Close
                style={{
                  "--svg_current_color": "#fff",
                  width: 12,
                  height: 12,
                }}
                clickhandel={() => {
                  setclient_table_filter((prev) => ({
                    ...prev,
                    date: "",
                  }));
                }}
              />
            </div>
          )}
        </div>
        {to_show_table_details == "default" ? (
          <Table
            cust_class="client_table_main"
            type="type2"
            table_head_data={[
              "Profile Pic",
              "name",
              "MOBILE",
              "EMAIL",
              "CC ON FILE",
              "Signup Date",
              "actions",
            ]}
            table_body_data={[
              ...to_show_client_table
                .filter((el) => {
                  if (Object.keys(el).indexOf("archived") == -1) {
                    return true;
                  }
                  if (client_table_filter.is_archived === 1) {
                    return el?.archived && el.archived === 1;
                  } else {
                    return el.archived === 0;
                  }
                })
                .filter((el) => {
                  if (
                    client_table_filter.date &&
                    client_table_filter.date !== ""
                  ) {
                    return (
                      new Date(el.created_at) -
                        new Date(client_table_filter.date) ===
                      0
                    );
                  }
                  return true;
                })
                .map((el, i) => [
                  <div
                    style={{
                      borderRadius: "50%",
                      overflow: "hidden",
                      height: "27px",
                      width: "27px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img src={el.img} height="27px" />
                  </div>,
                  <div
                    onClick={() => {
                      var copyText = `${el.name}`;
                      navigator.clipboard.writeText(copyText);
                    }}
                  >
                    {el.name}
                  </div>,
                  // el.id,
                  <div
                    onClick={() => {
                      var copyText = `${mobile_convert_display(el.phone)}`;
                      navigator.clipboard.writeText(copyText);
                    }}
                  >
                    {mobile_convert_display(el.phone)}
                  </div>,
                  <div
                    onClick={() => {
                      var copyText = `${el.email || ""}`;
                      navigator.clipboard.writeText(copyText);
                    }}
                  >
                    {el.email || "-"}
                  </div>,
                  <>{el.payment != "" ? `**** **** ${el.payment}` : "-"}</>,
                  // el.last_order || "-",
                  el.created_at || "-",
                  <div
                    className="client_table_menu_main"
                    show={(
                      `setclient_table_menu${i}` == client_table_menu
                    ).toString()}
                  >
                    {/* <img
                      src={client_table_menu_icon}
                      onClick={() => {
                          if (client_table_menu == `setclient_table_menu${i}`) {
                              return setclient_table_menu(``)
                          }
                          setclient_table_menu(`setclient_table_menu${i}`)
                      }}
                  /> */}
                    <MenuDots
                      clickhandel={() => {
                        if (client_table_menu == `setclient_table_menu${i}`) {
                          return setclient_table_menu(``);
                        }
                        setclient_table_menu(`setclient_table_menu${i}`);
                      }}
                    />
                    <ClientTableMenu
                      setclient_table_menu={setclient_table_menu}
                      handelonclick={[
                        () => {
                          setto_show_table_details("view");
                          setcurrent_showing_client([el, i]);
                          setclient_table_menu(``);
                          setTimeout(() => {
                            document
                              .getElementById(
                                el.name.toLowerCase().split(" ").join("") + i
                              )
                              .scrollIntoView({
                                behavior: "smooth",
                                block: "end",
                                inline: "nearest",
                              });
                            // document.getElementById(el.name.toLowerCase().split(' ').join('') + i).className += ' client_mini_menu_active'
                          }, 100);
                        },
                        () => {
                          setto_show_table_details("edit");
                          setcurrent_showing_client([el, i]);
                          setclient_table_menu(``);
                          setTimeout(() => {
                            document
                              .getElementById(
                                el.name.toLowerCase().split(" ").join("") + i
                              )
                              .scrollIntoView({
                                behavior: "smooth",
                                block: "end",
                                inline: "nearest",
                              });
                            // document.getElementById(el.name.toLowerCase().split(' ').join('') + i).className += ' client_mini_menu_active'
                          }, 100);
                          // setTimeout(() => {
                          //   // document.getElementById(el.name.toLowerCase().split(' ').join('') + i).scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
                          //   // document.getElementById(el.name.toLowerCase().split(' ').join('') + i).className += ' client_mini_menu_active'
                          // }, 100);
                        },
                        () => {
                          // setcurrent_showing_client([el, i]);
                          // setto_show_table_details("edit");
                          setloader(true);
                          createorder(
                            {
                              client_id: el.id,
                              address: el.address || "no",
                              apt: el.apt || "no",
                              zipcode: el.zipcode || "no",
                            },
                            current_store.user.token,
                            (data) => {
                              if (!data.status) {
                                return navigate("/");
                              }
                              // data.data.order_id
                              // setTimeout(() => {
                              getorderbyid(
                                data.data.order_id,
                                current_store.user.token,
                                (data) => {
                                  getclient_byid(
                                    data.data.sql_data.client_id,
                                    current_store.user.token,
                                    (data1) => {
                                      setclient_table_menu(``);
                                      const order_statuses = [
                                        // "All",
                                        "Pickup Requested",
                                        "Driver Enroute to Pickup",
                                        "Picked Up",
                                        "Customer Charged",
                                        "Deliver Clothes",
                                        "Driver Enroute to Dropoff",
                                        // "Order Cancelled",
                                        "Order Completed",
                                      ];
                                      // return;

                                      setdata({
                                        order_id: data.data.sql_data.order_id,
                                        notes: data.data.sql_data.notes,
                                        name: data.data.sql_data.client_name,
                                        address:
                                          // "",
                                          data.data.sql_data.address,
                                        phone:
                                          // data.data.sql_data.mobile ||
                                          mobile_convert_display(
                                            data.data.sql_data.mobile
                                          ),
                                        payment: data.data.sql_data.last_4,
                                        status_order_data: [
                                          ...data.data.status_logs,
                                          // null,
                                        ],
                                        // status_order_data: [],
                                        order_created:
                                          data.data.sql_data.order_date,
                                        card_list: data1.data.cards,
                                        client_id: data.data.sql_data.client_id,
                                        charged: data.data.sql_data.charged,
                                        delivered: data.data.sql_data.delivered,
                                        client_particulars: data.data.sql_data
                                          .client_particulars || {
                                          others: "0",
                                          laundry: "0",
                                          drycleaning: "0",
                                        },
                                        file_data: {
                                          ...(["undefined", ""].indexOf(
                                            data.data.file_data
                                          ) != -1
                                            ? {}
                                            : JSON.parse(data.data.file_data)),
                                        },
                                      });
                                      setloader(false);
                                      navigate("/add-order");
                                    }
                                  );
                                }
                              );
                              // }, 10000);
                            }
                          );
                        },
                        () => {
                          setclient_table_menu(``);
                          // console.log("pre order");
                        },
                        () => {
                          setto_show_table_details("view");
                          setcurrent_showing_client([el, i]);
                          setclient_table_menu(``);
                          setclient_menu("orderhistory");
                        },
                        () => {
                          setto_show_table_details("view");
                          setclient_menu("analytics");
                          setcurrent_showing_client([el, i]);
                          setclient_table_menu(``);
                          // setTimeout(() => {
                          //     document.getElementById(el.name.toLowerCase().split(' ').join('') + i).scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
                          // }, 100)
                        },
                        () => {
                          setto_show_table_details("view");
                          setclient_menu("futureprediction");
                          setcurrent_showing_client([el, i]);
                          setclient_table_menu(``);
                          // setTimeout(() => {
                          //     document.getElementById(el.name.toLowerCase().split(' ').join('') + i).scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
                          // }, 100)
                        },
                        () => {
                          // console.log("archive ths client");
                          set_archive(
                            {
                              id: el.id,
                              archived: el.archived === 0 ? "1" : "0",
                            },
                            current_store.user.token,
                            (data) => {
                              // console.log(data.data.clients);
                              setto_show_client_table([
                                ...data.data.clients.map((el) => {
                                  return {
                                    id: el.id,
                                    img:
                                      el.profile_photo_link == null ||
                                      el.profile_photo_link ==
                                        "https://julietteimages.s3.amazonaws.com/profileimages/default.png"
                                        ? "https://julietteimages.s3.amazonaws.com/profileimages/default.png?1656920271059"
                                        : el.profile_photo_link,
                                    name: el.full_name,
                                    phone: el.mobile,
                                    payment: el.cc_last_4_digits
                                      ? (el.cc_last_4_digits.toString()
                                          .length == 3
                                          ? "0"
                                          : el.cc_last_4_digits.toString()
                                              .length == 2
                                          ? "00"
                                          : el.cc_last_4_digits.toString()
                                              .length == 1
                                          ? "000"
                                          : "") + el.cc_last_4_digits
                                      : "",
                                    last_order: el.order_date
                                      ? el.order_date.split("T")[0]
                                      : "",
                                    email: el.email,
                                    address: el.address,
                                    apt: el.apt,
                                    zipcode: el.zipcode,
                                    archived: el?.archived
                                      ? Number(el.archived)
                                      : 0,
                                    created_at: `${
                                      new Date(el.created_at).getDate() < 10
                                        ? "0"
                                        : ""
                                    }${new Date(el.created_at)
                                      .getDate()
                                      .toString()} ${month[
                                      new Date(el.created_at).getMonth()
                                    ].toString()} ${new Date(el.created_at)
                                      .getFullYear()
                                      .toString()}`,
                                  };
                                }),
                              ]);
                              setclient_table_original([
                                ...data.data.clients.map((el) => {
                                  return {
                                    id: el.id,
                                    img:
                                      el.profile_photo_link == null ||
                                      el.profile_photo_link ==
                                        "https://julietteimages.s3.amazonaws.com/profileimages/default.png"
                                        ? "https://julietteimages.s3.amazonaws.com/profileimages/default.png?1656920271059"
                                        : el.profile_photo_link,
                                    name: el.full_name,
                                    phone: el.mobile,
                                    payment: el.cc_last_4_digits
                                      ? (el.cc_last_4_digits.toString()
                                          .length == 3
                                          ? "0"
                                          : el.cc_last_4_digits.toString()
                                              .length == 2
                                          ? "00"
                                          : el.cc_last_4_digits.toString()
                                              .length == 1
                                          ? "000"
                                          : "") + el.cc_last_4_digits
                                      : "",
                                    last_order: el.order_date
                                      ? el.order_date.split("T")[0]
                                      : "",
                                    email: el.email,
                                    address: el.address,
                                    apt: el.apt,
                                    zipcode: el.zipcode,
                                    archived: el?.archived
                                      ? Number(el.archived)
                                      : 0,
                                    created_at: `${
                                      new Date(el.created_at).getDate() < 10
                                        ? "0"
                                        : ""
                                    }${new Date(el.created_at)
                                      .getDate()
                                      .toString()} ${month[
                                      new Date(el.created_at).getMonth()
                                    ].toString()} ${new Date(el.created_at)
                                      .getFullYear()
                                      .toString()}`,
                                  };
                                }),
                              ]);
                            }
                          );
                        },
                        () => {
                          console.log(el);
                          var copyText = [
                            `Name - ${el.name}`,
                            `Mobile - ${mobile_convert_display(el.phone)}`,
                            `Email - ${el.email}`,
                            `Apt - ${el.apt}`,
                            `Address - ${el.address}`,
                            `Zipcode - ${el.zipcode}`,
                          ].join("\n");
                          navigator.clipboard.writeText(copyText);
                          alert("Copied details of: " + el.name);
                          setclient_table_menu(``);
                        },
                      ]}
                    />
                  </div>,
                ]),
              // .slice(0, 10),
            ]}
          />
        ) : to_show_table_details == "view" ||
          to_show_table_details == "edit" ? (
          <>
            <div className="client_details">
              <div className="left_div" id="client_mini_menu">
                {to_show_client_table
                  .filter((el) => {
                    if (Object.keys(el).indexOf("archived") == -1) {
                      return true;
                    }
                    if (client_table_filter.is_archived === 1) {
                      return el?.archived && el.archived === 1;
                    } else {
                      return el.archived === 0;
                    }
                  })

                  .filter((el) => {
                    if (
                      client_table_filter.date &&
                      client_table_filter.date !== ""
                    ) {
                      return (
                        new Date(el.created_at) -
                          new Date(client_table_filter.date) ===
                        0
                      );
                    }
                    return true;
                  })
                  .map((el, i) => (
                    <div
                      className={
                        "client_view_cards" +
                        (current_showing_client[0].name
                          .toLowerCase()
                          .split(" ")
                          .join("") +
                          current_showing_client[1] ==
                        el.name.toLowerCase().split(" ").join("") + i
                          ? " client_mini_menu_active"
                          : "")
                      }
                      id={el.name.toLowerCase().split(" ").join("") + i}
                      onClick={() => {
                        setaddress_arr(["test"]);
                        setcurrent_showing_client([{}, -1]);
                        setcurrent_showing_client([el, i]);
                        setto_show_table_details("view");
                        setclient_menu("view");
                      }}
                    >
                      <div
                        style={{
                          height: "23px",
                          width: "23px",
                          borderRadius: "50%",
                          overflow: "hidden",
                          marginRight: "13px",
                        }}
                      >
                        <img src={el.img} width="100%" />
                      </div>
                      <div
                        className="client_card_name"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "calc(100% - 36px)",
                        }}
                      >
                        {el.name}
                      </div>
                    </div>
                  ))}
              </div>
              <div
                className={
                  "right_div " +
                  (to_show_table_details == "view" && client_menu == "view"
                    ? "view_details"
                    : to_show_table_details == "edit" && client_menu == "view"
                    ? "edit_details"
                    : client_menu == "orderhistory"
                    ? "orderhistory_details"
                    : client_menu == "analytics"
                    ? "analytics_details"
                    : "")
                }
              >
                <ClientMenu
                  client_menu={client_menu}
                  setclient_menu={setclient_menu}
                  to_show_table_details={to_show_table_details}
                  setto_show_table_details={setto_show_table_details}
                />
                {client_menu == "view" ? (
                  <>
                    {to_show_table_details == "view" ? (
                      // <div className='right_div view_details'>
                      //     <ClientMenu
                      //         client_menu={client_menu}
                      //         setclient_menu={setclient_menu}
                      //         setto_show_table_details={setto_show_table_details}
                      //     />
                      <>
                        <div className="right1 ">
                          <div
                            className="name"
                            style={{
                              textAlign: "center",
                              padding: "0 0 10px 0",
                              // marginTop: "auto",
                              // fontWeight: "bold",
                            }}
                          >
                            Since{" "}
                            <>
                              {new Date(current_showing_client_data.created_at)
                                // .toDateString()
                                .getDate()
                                .toString()}{" "}
                              {
                                month[
                                  new Date(
                                    current_showing_client_data.created_at
                                  )
                                    // .toDateString()
                                    .getMonth()
                                ]
                              }{" "}
                              {new Date(current_showing_client_data.created_at)
                                .getFullYear()
                                .toString()}{" "}
                              {/* test
                              {current_showing_client_data.created_at}
                              test */}
                              {(new Date(
                                current_showing_client_data.created_at
                              ).getHours() > 12
                                ? `${
                                    new Date(
                                      current_showing_client_data.created_at
                                    ).getHours() -
                                      12 <
                                    10
                                      ? `0${
                                          new Date(
                                            current_showing_client_data.created_at
                                          ).getHours() - 12
                                        }`
                                      : `${
                                          new Date(
                                            current_showing_client_data.created_at
                                          ).getHours() - 12
                                        }`
                                  }`
                                : new Date(
                                    current_showing_client_data.created_at
                                  ).getHours() < 10
                                ? `0${new Date(
                                    current_showing_client_data.created_at
                                  ).getHours()}`
                                : `${new Date(
                                    current_showing_client_data.created_at
                                  ).getHours()}`
                              ).toString()}{" "}
                              :{" "}
                              {(new Date(
                                current_showing_client_data.created_at
                              ).getMinutes() < 10
                                ? "0"
                                : "") +
                                new Date(current_showing_client_data.created_at)
                                  .getMinutes()
                                  .toString()}{" "}
                              {new Date(
                                current_showing_client_data.created_at
                              ).getHours() > 12
                                ? "PM"
                                : "AM"}
                            </>
                          </div>
                          <div className="personal_details">
                            <div
                              className="img"
                              style={{
                                borderRadius: "50%",
                                overflow: "hidden",
                                height: "73px",
                                width: "73px",
                                margin: "0 auto 0 auto",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={current_showing_client_data.img}
                                style={{ height: "100%", width: "auto" }}
                              />
                            </div>
                            <div className="details">
                              <div className="name">
                                {current_showing_client_data.name}
                              </div>
                              <div className="email">
                                {current_showing_client_data.email}
                              </div>
                              <div className="phone">
                                {mobile_convert_display(
                                  current_showing_client_data.phone
                                )}
                              </div>
                              <div className="app">#app</div>
                            </div>
                          </div>

                          <div
                            className="client_credit"
                            style={{ display: "none" }}
                          >
                            <div>CLIENT CREDIT</div>
                            <div> $ 10,50</div>
                          </div>

                          <div
                            className="open_amount"
                            // style={
                            //   !(current_showing_client_data.pay_later_amount - current_showing_client_data.paidTotal <= 0)
                            //     ? { visibility: "hidden" }
                            //     : {}
                            // }
                          >
                            <div>OPEN AMOUNT</div>
                            <div className="amount">
                              ${" "}
                              {current_showing_client_data.pay_later_amount -
                                current_showing_client_data.paidTotal <=
                              0
                                ? 0
                                : current_showing_client_data.pay_later_amount -
                                  current_showing_client_data.paidTotal}
                            </div>

                            {current_showing_client_data.pay_later_amount -
                              current_showing_client_data.paidTotal >
                              0 && (
                              <div
                                className="btn"
                                type="type4"
                                onClick={() => setclientchargenowmodal(true)}
                              >
                                Charge Now
                              </div>
                            )}
                            {clientchargenowmodal && (
                              <ClientChargeNowModal
                                setclientchargenowmodal={
                                  setclientchargenowmodal
                                }
                                setcurrent_showing_client_data={
                                  setcurrent_showing_client_data
                                }
                                setloader={setloader}
                                client_data={current_showing_client_data}
                              />
                            )}
                          </div>
                        </div>
                        <div className="right2">
                          <div className="right2_cards">
                            <div className="card_title">Primary Adress</div>
                            <div className="card_details">
                              {current_showing_client_data.address?.length >
                              0 ? (
                                <>
                                  {current_showing_client_data.address?.length >
                                    1 && (
                                    <>
                                      <div className="prev_address">
                                        <img
                                          src={arrow_left}
                                          onClick={() => {
                                            setaddress_arr_index(
                                              address_arr_index == 0
                                                ? current_showing_client_data
                                                    .address.length - 1
                                                : address_arr_index - 1
                                            );
                                          }}
                                        />
                                      </div>
                                      <div className="next_address">
                                        <img
                                          src={arrow_right}
                                          onClick={() => {
                                            setaddress_arr_index(
                                              address_arr_index + 1 ==
                                                current_showing_client_data
                                                  .address.length
                                                ? 0
                                                : address_arr_index + 1
                                            );
                                          }}
                                        />
                                      </div>
                                    </>
                                  )}
                                  <div>
                                    {`${current_showing_client_data.address[address_arr_index].address}, ${current_showing_client_data.address[address_arr_index].apartment}, ${current_showing_client_data.address[address_arr_index].zipcode}`}
                                  </div>
                                </>
                              ) : (
                                <div>No Address on file</div>
                              )}
                            </div>
                          </div>
                          <div className="right2_cards">
                            <div className="card_title">Primary Creditcard</div>
                            <div className="card_details">
                              {/* {
                                                                                current_showing_client_data.cards.length.map(el => el)
                                                                            } */}
                              {current_showing_client_data.cards?.length > 0 ? (
                                <>
                                  {current_showing_client_data.cards.length >
                                    1 && (
                                    <>
                                      <div className="prev_address">
                                        <img
                                          src={arrow_left}
                                          onClick={() => {
                                            setcc_arr_index(
                                              cc_arr_index == 0
                                                ? current_showing_client_data
                                                    .cards.length - 1
                                                : cc_arr_index - 1
                                            );
                                          }}
                                        />
                                      </div>
                                      <div className="next_address">
                                        <img
                                          src={arrow_right}
                                          onClick={() => {
                                            setcc_arr_index(
                                              cc_arr_index + 1 ==
                                                current_showing_client_data
                                                  .cards.length
                                                ? 0
                                                : cc_arr_index + 1
                                            );
                                          }}
                                        />
                                      </div>
                                    </>
                                  )}
                                  <div>
                                    <div
                                      div
                                      className="cc_type"
                                      fcolor="grey50"
                                    >
                                      {
                                        current_showing_client_data.cards[
                                          cc_arr_index
                                        ]?.card_type
                                      }
                                    </div>
                                    <div className="cc_no" fcolor="grey50">
                                      **** **** ****{" "}
                                      {
                                        current_showing_client_data.cards[
                                          cc_arr_index
                                        ]?.cc_last_4_digits
                                      }
                                    </div>
                                    {/* {`${current_showing_client_data.cards[cc_arr_index]?.card_arr_name}`} */}
                                  </div>
                                </>
                              ) : (
                                <div>No CC on file</div>
                              )}
                            </div>
                          </div>
                          <div className="right2_cards">
                            <div className="card_title">Notes</div>
                            <div className="card_details card_notes_details">
                              {current_showing_client_data.notes || "-"}
                            </div>
                          </div>
                          <div className="right2_btn_group">
                            <div
                              className="btn"
                              type="type2"
                              onClick={() => {
                                setto_show_table_details("edit");
                                settemp_data({
                                  ...current_showing_client_data,
                                });
                                // settemp_data({
                                //     name: current_showing_client.name,
                                //     // email:current_showing_client.nam,
                                //     cc: current_showing_client.payment,
                                //     notes: current_showing_client.notes
                                // })
                              }}
                            >
                              Edit Client
                            </div>
                            <div
                              className="btn"
                              type="type2"
                              onClick={() => {
                                let temp_address_details =
                                  current_showing_client_data.address.filter(
                                    (el) =>
                                      [1, "1"].indexOf(el.is_default) != -1
                                  )[0];

                                let temp_obj = {
                                  client_id: current_showing_client_data.id,
                                  address:
                                    temp_address_details?.address || "no",
                                  apt: temp_address_details?.apartment || "no",
                                  zipcode:
                                    temp_address_details?.zipcode || "no",
                                  // token: current_store.user.token,
                                };
                                // return;
                                setloader(true);
                                createorder(
                                  {
                                    ...temp_obj,
                                  },
                                  current_store.user.token,
                                  (data) => {
                                    if (!data.status) {
                                      return navigate("/");
                                    }

                                    // setTimeout(() => {
                                    getorderbyid(
                                      data.data.order_id,
                                      current_store.user.token,
                                      (data) => {
                                        getclient_byid(
                                          data.data.sql_data.client_id,
                                          current_store.user.token,
                                          (data1) => {
                                            const order_statuses = [
                                              // "All",
                                              "Pickup Requested",
                                              "Driver Enroute to Pickup",
                                              "Picked Up",
                                              "Customer Charged",
                                              "Deliver Clothes",
                                              "Driver Enroute to Dropoff",
                                              // "Order Cancelled",
                                              "Order Completed",
                                            ];
                                            // return;

                                            setdata({
                                              order_id:
                                                data.data.sql_data.order_id,
                                              notes: data.data.sql_data.notes,
                                              name: data.data.sql_data
                                                .client_name,
                                              address:
                                                // "",
                                                data.data.sql_data.address,
                                              phone:
                                                // data.data.sql_data.mobile ||
                                                mobile_convert_display(
                                                  data.data.sql_data.mobile
                                                ),
                                              payment:
                                                data.data.sql_data.last_4,
                                              status_order_data: [
                                                ...data.data.status_logs,
                                                // null,
                                              ],
                                              // status_order_data: [],
                                              order_created:
                                                data.data.sql_data.order_date,
                                              card_list: data1.data.cards,
                                              client_id:
                                                data.data.sql_data.client_id,
                                              charged:
                                                data.data.sql_data.charged,
                                              delivered:
                                                data.data.sql_data.delivered,
                                              client_particulars: data.data
                                                .sql_data
                                                .client_particulars || {
                                                others: "0",
                                                laundry: "0",
                                                drycleaning: "0",
                                              },
                                              file_data: {
                                                ...(["undefined", ""].indexOf(
                                                  data.data.file_data
                                                ) != -1
                                                  ? {}
                                                  : JSON.parse(
                                                      data.data.file_data
                                                    )),
                                              },
                                            });
                                            setloader(false);
                                            navigate("/add-order");
                                          }
                                        );
                                      }
                                    );
                                    // }, 10000);
                                  }
                                );
                              }}
                            >
                              + Order
                            </div>
                          </div>
                        </div>
                      </>
                    ) : to_show_table_details == "edit" ? (
                      <>
                        <div className="right1">
                          <div className="personal_details">
                            <div
                              className="name"
                              style={{
                                textAlign: "center",
                                padding: "0 0 10px 0",
                                // fontWeight: "bold",
                              }}
                            >
                              Since{" "}
                              <>
                                {new Date(
                                  current_showing_client_data.created_at
                                )
                                  // .toDateString()
                                  .getDate()
                                  .toString()}{" "}
                                {
                                  month[
                                    new Date(
                                      current_showing_client_data.created_at
                                    )
                                      // .toDateString()
                                      .getMonth()
                                  ]
                                }{" "}
                                {new Date(
                                  current_showing_client_data.created_at
                                )
                                  // .toDateString()
                                  .getFullYear()
                                  .toString()}{" "}
                                {(new Date(
                                  current_showing_client_data.created_at
                                )
                                  // .toDateString()
                                  .getHours() > 12
                                  ? `0${
                                      new Date(
                                        current_showing_client_data.created_at
                                      )
                                        // .toDateString()
                                        .getHours() - 12
                                    }`
                                  : new Date(
                                      current_showing_client_data.created_at
                                    )
                                      // .toDateString()
                                      .getHours()
                                ).toString()}{" "}
                                :{" "}
                                {new Date(
                                  current_showing_client_data.created_at
                                )
                                  // .toDateString()
                                  .getMinutes()
                                  .toString()}{" "}
                                {new Date(
                                  current_showing_client_data.created_at
                                )
                                  // .toDateString()
                                  .getHours() > 12
                                  ? "PM"
                                  : "AM"}
                              </>
                            </div>
                            <div
                              className="profile_pic"
                              style={{
                                borderRadius: "50%",
                                overflow: "hidden",
                                height: "105px",
                                width: "105px",
                                margin: "0 auto 0 auto",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={current_showing_client_data.img}
                                height="100%"
                              />
                            </div>
                            <div className="details">
                              <div className="name">
                                <div className="title">Name</div>
                                <input
                                  type="text"
                                  value={temp_data.name}
                                  onChange={(e) => {
                                    settemp_data({
                                      ...temp_data,
                                      name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                              <div className="name">
                                <div className="title">Email</div>
                                <input
                                  type="text"
                                  value={temp_data.email}
                                  onChange={(e) => {
                                    settemp_data({
                                      ...temp_data,
                                      email: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                              <div className="name">
                                <div className="title">Phone Number</div>
                                <input
                                  type="number"
                                  value={`${temp_data.phone?.slice(1)}`}
                                  onChange={(e) => {
                                    settemp_data({
                                      ...temp_data,
                                      phone: `1${e.target.value}`,
                                    });
                                  }}
                                />
                              </div>
                            </div>

                            <div
                              className="client_credit"
                              style={{ visibility: "hidden" }}
                            >
                              <div>CLIENT CREDIT</div>
                              <div>$ 10,50</div>
                            </div>
                          </div>
                        </div>
                        <div className="right2">
                          <div className="right2_cards">
                            <div className="card_title">
                              Primary Adress
                              <img
                                src={add_icon}
                                onClick={() =>
                                  seteditclient_add_address_modal(true)
                                }
                              />
                            </div>
                            <div className="card_details">
                              {temp_data.address?.length > 0 ? (
                                <>
                                  {temp_data.address?.length > 1 && (
                                    <>
                                      <div className="prev_address">
                                        <img
                                          src={arrow_left}
                                          onClick={() => {
                                            setaddress_arr_index(
                                              address_arr_index == 0
                                                ? temp_data.address.length - 1
                                                : address_arr_index - 1
                                            );
                                          }}
                                        />
                                      </div>
                                      <div className="next_address">
                                        <img
                                          src={arrow_right}
                                          onClick={() => {
                                            setaddress_arr_index(
                                              address_arr_index + 1 ==
                                                temp_data.address.length
                                                ? 0
                                                : address_arr_index + 1
                                            );
                                          }}
                                        />
                                      </div>
                                    </>
                                  )}
                                  <div>
                                    {`${temp_data.address[address_arr_index].address}, ${temp_data.address[address_arr_index].apartment}, ${temp_data.address[address_arr_index].zipcode}`}
                                  </div>
                                </>
                              ) : (
                                <div>No Address on file</div>
                              )}
                            </div>
                          </div>
                          <div className="right2_cards">
                            <div className="card_title">
                              Primary Creditcard
                              <img
                                src={add_icon}
                                onClick={() =>
                                  seteditclient_add_creditcard_modal(true)
                                }
                              />
                            </div>
                            <div className="card_details card_cc_details">
                              {current_showing_client_data.cards?.length > 0 ? (
                                <>
                                  {current_showing_client_data.cards.length >
                                    1 && (
                                    <>
                                      <div className="prev_address">
                                        <img
                                          src={arrow_left}
                                          onClick={() => {
                                            setcc_arr_index(
                                              cc_arr_index == 0
                                                ? current_showing_client_data
                                                    .cards.length - 1
                                                : cc_arr_index - 1
                                            );
                                          }}
                                        />
                                      </div>
                                      <div className="next_address">
                                        <img
                                          src={arrow_right}
                                          onClick={() => {
                                            setcc_arr_index(
                                              cc_arr_index + 1 ==
                                                current_showing_client_data
                                                  .cards.length
                                                ? 0
                                                : cc_arr_index + 1
                                            );
                                          }}
                                        />
                                      </div>
                                    </>
                                  )}
                                  <div>
                                    <div
                                      div
                                      className="cc_type"
                                      fcolor="grey50"
                                    >
                                      {
                                        current_showing_client_data.cards[
                                          cc_arr_index
                                        ]?.card_type
                                      }
                                      {/* {cc_arr_index} */}
                                    </div>
                                    <div className="cc_no" fcolor="grey50">
                                      **** **** ****{" "}
                                      {
                                        current_showing_client_data.cards[
                                          cc_arr_index
                                        ]?.cc_last_4_digits
                                      }
                                    </div>
                                    {/* {`${current_showing_client_data.cards[cc_arr_index]?.card_arr_name}`} */}
                                  </div>
                                </>
                              ) : (
                                <div>No CC on file</div>
                              )}
                            </div>
                          </div>
                          <div className="right2_cards">
                            <div className="card_title">Notes</div>
                            <div className="card_details card_notes_details">
                              <textarea
                                style={{ resize: "none", border: 0 }}
                                value={temp_data.notes}
                                onChange={(e) =>
                                  settemp_data({
                                    ...temp_data,
                                    notes: e.target.value,
                                  })
                                }
                                rows="4"
                                cols="37"
                              />
                            </div>
                          </div>
                          <div className="right2_btn_group">
                            <div
                              className="btn"
                              type="type1"
                              style={{ cursor: "no-drop" }}
                            >
                              Delete Client
                            </div>
                            <div
                              className="btn"
                              type={
                                current_showing_client_data.name ==
                                  temp_data.name &&
                                current_showing_client_data.email ==
                                  temp_data.email &&
                                current_showing_client_data.phone ==
                                  temp_data.phone &&
                                current_showing_client_data.notes ==
                                  temp_data.notes
                                  ? "type1"
                                  : "type2"
                              }
                              onClick={() => {
                                if (
                                  current_showing_client_data.name ==
                                    temp_data.name &&
                                  current_showing_client_data.email ==
                                    temp_data.email &&
                                  current_showing_client_data.phone ==
                                    temp_data.phone &&
                                  current_showing_client_data.notes ==
                                    temp_data.notes
                                ) {
                                  return;
                                }
                                editclient(
                                  {
                                    client_id: current_showing_client_data.id,
                                    name: temp_data.name,
                                    email: temp_data.email,
                                    notes: temp_data.notes,
                                    phone: temp_data.phone,
                                  },
                                  current_store.user.token,
                                  (data) => {
                                    if (!data.status) {
                                      return navigate("/404");
                                    }
                                    window.location.reload();
                                  }
                                );
                                // setto_show_table_details('default')
                              }}
                            >
                              {" "}
                              Save
                            </div>
                          </div>
                        </div>
                        {editclient_add_address_modal && (
                          <EditClientAddAddressModal
                            seteditclient_add_address_modal={
                              seteditclient_add_address_modal
                            }
                            settemp_address_arr={settemp_address_arr}
                            temp_address_arr={temp_address_arr}
                            address_arr={address_arr}
                            setaddress_arr={setaddress_arr}
                            setloader={setloader}
                            temp_data={temp_data}
                            settemp_data={settemp_data}
                          />
                        )}
                        {editclient_add_creditcard_modal && (
                          <EditClientAddCreditcardModal
                            seteditclient_add_creditcard_modal={
                              seteditclient_add_creditcard_modal
                            }
                            temp_ccdatamodal_arr={temp_ccdatamodal_arr}
                            settemp_ccdatamodal_arr={settemp_ccdatamodal_arr}
                            temp_data={temp_data}
                            settemp_data={settemp_data}
                            setcurrent_showing_client_data={
                              setcurrent_showing_client_data
                            }
                            current_showing_client_data={
                              current_showing_client_data
                            }
                            // cc_arr={cc_arr}
                            // setcc_arr={setcc_arr}
                          />
                        )}
                        {/* </div> */}
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : client_menu == "orderhistory" ? (
                  <>
                    {/* <SearchAndFilterBar
                      placeholder="Search for Paid, Status, Date"
                      change_search={order_history_search}
                      handlechange_search={(data) => {
                        setorder_history_search(data);
                      }}
                      // filter_show="no"
                    /> */}
                    {!current_showing_client_data.orders?.length ? (
                      <div style={{ textAlign: "center" }}>No Order</div>
                    ) : (
                      <Table
                        cust_class="client_order_history_table"
                        type="type1"
                        table_head_data={[
                          "PAYMENT",
                          "STATUS",
                          "PAYMENT STATUS",
                          "ORDER CREATED",
                          "ACTION",
                        ]}
                        table_body_data={[
                          ...current_showing_client_data.orders?.map((el) => {
                            return [
                              el.amount ? `$${el.amount}` : "-",
                              // el.payment || "-",
                              // order_statuses[el.order_status],
                              el.display_status ||
                                order_statuses[el.order_status],
                              order_statuses.indexOf(el.display_status) < 4 ? (
                                <div fcolor="red">UNPAID</div>
                              ) : (
                                <div fcolor="green">PAID</div>
                              ),
                              new Date(el.order_date)
                                // .toDateString()
                                .toDateString()
                                .split(" ")
                                .slice(1, 4)
                                .join(" "),
                              [
                                "Customer Charged",
                                "Deliver Clothes",
                                "Driver Enroute to Dropoff",
                                "Order Completed",
                              ].includes(el.display_status) && (
                                <Receipt
                                  clickhandel={() => {
                                    navigate("/receipt/" + el.order_id);
                                  }}
                                />
                              ),
                              [
                                "Deliver Clothes",
                                "Driver Enroute to Dropoff",
                                "Delivery Failed",
                                "Order Cancelled",
                                "Order Completed",
                              ].includes(el.display_status) && (
                                <Eye
                                  style={{
                                    margin: "0 0 0 10px",
                                  }}
                                  clickhandel={() => {
                                    // return;
                                    getorderbyid(
                                      el.order_id,
                                      current_store.user.token,
                                      (data) => {
                                        getclient_byid(
                                          data.data.sql_data.client_id,
                                          current_store.user.token,
                                          (data1) => {
                                            const order_statuses = [
                                              "Pickup Requested",
                                              "Driver Enroute to Pickup",
                                              "Picked Up",
                                              "Customer Charged",
                                              "Deliver Clothes",
                                              "Driver Enroute to Dropoff",
                                              "Order Completed",
                                            ];

                                            setview_order({
                                              order_id:
                                                data.data.sql_data.order_id,
                                              notes: data.data.sql_data.notes,
                                              name: data.data.sql_data
                                                .client_name,
                                              address:
                                                data.data.sql_data.address,
                                              phone: mobile_convert_display(
                                                data.data.sql_data.mobile
                                              ),
                                              payment:
                                                data.data.sql_data.last_4,
                                              status_order_data: [
                                                ...data.data.status_logs,
                                              ],
                                              order_created:
                                                data.data.sql_data.order_date,
                                              card_list: data1.data.cards,
                                              client_id:
                                                data.data.sql_data.client_id,
                                              client_particulars: data.data
                                                .sql_data
                                                .client_particulars || {
                                                others: "0",
                                                laundry: "0",
                                                drycleaning: "0",
                                              },
                                              file_data: {
                                                ...(["undefined", ""].indexOf(
                                                  data.data.file_data
                                                ) != -1
                                                  ? {}
                                                  : JSON.parse(
                                                      data.data.file_data
                                                    )),
                                              },
                                            });
                                            setloader(false);
                                            navigate("/view-order");
                                          }
                                        );
                                      }
                                    );
                                  }}
                                />
                              ),
                            ];
                          }),
                        ]}
                      />
                    )}
                  </>
                ) : client_menu == "analytics" ? (
                  <div className="analytics_body">
                    {analytics_data.og_arr &&
                    analytics_data.og_arr.length > 0 ? (
                      <>
                        <div className="right1">
                          <CustDateRangePicker
                            custclass="analytics_datepicker"
                            date={analytics_date}
                            setdate={setanalytics_date}
                            data={analytics_data}
                            id="customdate1"
                          />
                        </div>
                        <div className="right2">
                          <div className="cards_group">
                            <div className="cards">
                              <div className="title">TOTAL AMOUNT SPENT</div>
                              <div className="body">
                                ${analytics_data.total_amount || 0}
                              </div>
                            </div>
                            <div className="cards">
                              <div className="title">TOTAL ORDERS</div>
                              <div className="body">
                                {analytics_data.total_order}
                              </div>
                            </div>
                            <div className="cards">
                              <div className="title">TOTAL ITEMS</div>
                              <div className="body">
                                {analytics_data.total_items}
                              </div>
                            </div>
                          </div>
                          <div className="analytics_graph">
                            <Custgraph1
                              data={analytics_data}
                              date={analytics_date}
                              // setdate={setanalytics_date}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div
                        style={{ textAlign: "center", padding: "0 0 0 48px" }}
                      >
                        No Order
                      </div>
                    )}
                  </div>
                ) : client_menu == "futureprediction" ? (
                  <FuturePredictions
                    latest_values={latest_values}
                    values={values}
                    order_list={order_list}
                    calc_day={calc_day}
                    calc_avg={calc_avg}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      {addclientmodal && (
        <AddClientModal
          setaddclientmodal={setaddclientmodal}
          client_table_original={client_table_original}
        />
      )}
      {add_order_modal && (
        <AddOrderModal
          setdata={setdata}
          setadd_order_modal={setadd_order_modal}
          setloader={setloader}
          clientmini_data={[
            ...client_table_original
              .map((el) => {
                return {
                  id: el.id,
                  full_name: el.name,
                  img: el.id,
                  address: el.address,
                  apt: el.apt,
                  zipcode: el.zipcode,
                };
              })
              .sort((a, b) =>
                a.full_name.toLocaleLowerCase() <
                b.full_name.toLocaleLowerCase()
                  ? -1
                  : a.full_name.toLocaleLowerCase() >
                    b.full_name.toLocaleLowerCase()
                  ? 1
                  : 0
              ),
          ]}
        />
      )}
    </div>
  );
};
