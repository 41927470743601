import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./printorder.scss";
import { useDispatch, useSelector } from "react-redux";
import { checktoken, print_invoice_printer } from "../../API/API";
import { Close } from "../../Component/Common/Images/Images";

const order_name = (txt) => {
  if (txt.length == 3) {
    return [txt[0], txt[2]];
  }
  return txt;
};

export const PrintOrder = ({ setloader, setshow_navbar }) => {
  const params = useParams();
  const [page_data, setpage_data] = useState({
    order_id: "",
  });
  const [data_error, setdata_error] = useState(false);
  const current_store = useSelector((state) => state);
  const [is_admin, setis_admin] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    setloader(true);
    setshow_navbar(false);
    axios
      .post(
        `https://api.juliettecleaners.com/api/pos/v1/get-order-by-id`,
        {
          id: params.orderid,
        },
        {}
      )
      .then((response) => response.data)
      .then((response) => {
        setloader(false);
        if (response.error) {
          setdata_error(true);
          console.log("err", response);
          return;
        }
        let temp_file_data = JSON.parse(response.file_data);
        let temp_sub_total = temp_file_data.order_item.reduce(
          (prev, curr) => prev + Number(curr.total),
          0
        );
        let temp_discountdollars = temp_file_data?.discount_switch
          ? Number(temp_file_data?.globaldiscount)
          : temp_sub_total * (temp_file_data?.globaldiscount / 100);

        setpage_data({
          ...response,
          file_data: temp_file_data,
          sub_total: temp_sub_total,
          //   discount:page_data?.file_data?.globaldiscount,
          discount_dollars: temp_discountdollars.toFixed(2),
          total: (temp_sub_total - temp_discountdollars).toFixed(2),
        });
        return;
      })
      .catch((response) => {
        setloader(false);
        setdata_error(true);
        console.log("catch", response);
      });
  }, []);

  useEffect(() => {
    setshow_navbar(false);
    if (current_store.user.token == "") {
      let local_token = localStorage.getItem("jc_pos_token");
      if (local_token) {
        setloader(true);
        checktoken(local_token, (data) => {
          if (!data.status) {
            return navigate("/");
          }
          setis_admin(data.admin);
          dispatch({
            type: "login",
            payload: { token: local_token, admin: data.admin },
          });
          setloader(false);
        });
        return;
      } else {
        return navigate("/");
      }
    } else {
      setis_admin(current_store.user.admin);
      setloader(false);
    }

    let printer_ip = localStorage.getItem("jc_printer_ip");

    if (printer_ip) {
      dispatch({
        type: "put_default",
        payload: { printer_ip },
      });
    }
  }, [current_store.user.token]);

  console.log(current_store);

  // var canvas = document.getElementById("canvas");
  const print_Data = () => {
    console.log("started");
    var printer = null;
    var ePosDev = new window.epson.ePOSDevice();
    ePosDev.connect(current_store.default.printer_ip, 8008, cbConnect);
    function cbConnect(data) {
      console.log("started connection");
      if (data == "OK" || data == "SSL_CONNECT_OK") {
        ePosDev.createDevice(
          "local_printer",
          ePosDev.DEVICE_TYPE_PRINTER,
          { crypto: false, buffer: false },
          cbCreateDevice_printer
        );
      } else {
        console.log(data);
      }
    }
    function cbCreateDevice_printer(devobj, retcode) {
      console.log("checking connection");
      if (retcode == "OK") {
        printer = devobj;
        printer.timeout = 60000;
        printer.onreceive = function (res) {
          console.log(res);
          console.log(res.success);
        };
        printer.oncoveropen = function () {
          console.log("coveropen");
        };
        console.log(printer);
        console.log("started printing");
        print();
      } else {
        console.log(retcode);
      }
    }

    function print() {
      printer.addLayout(printer.LAYOUT_RECEIPT, 800, 0, 0, 0, 0, 0);
      printer.addFeed();
      printer.addFeed();
      printer.addFeed();
      printer.addFeed();
      printer.addFeed();
      printer.addTextLineSpace(20);
      printer.addText("\n");
      printer.addTextSmooth(true);
      printer.addTextDouble(true, true);
      printer.addTextStyle(false, false, true, printer.COLOR_1);
      printer.addTextSize(4, 4);
      printer.addText(`${page_data?.sql_data?.order_id}\n`);
      printer.addTextLineSpace(10);
      printer.addTextSize(2, 2);
      printer.addFeedUnit(10);
      printer.addText(`${page_data?.sql_data?.client_name}\n`);
      printer.addTextSize(1, 1);
      printer.addText(`${page_data?.sql_data?.address}\n`);
      printer.addFeedUnit(10);
      printer.addTextFont(printer.FONT_SPECIAL_B);
      printer.addTextLineSpace(20);
      printer.addText(
        `${
          page_data?.sql_data?.order_date +
          " " +
          page_data?.sql_data?.order_time
        }\n`
      );
      printer.addTextSize(2, 2);
      printer.addTextLineSpace(0);
      printer.addText(
        // "= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = \n"
        "= = = = = = = = = = = =\n"
      );
      page_data?.file_data?.order_item.map((el) => {
        let name = el.item_name.split("%ITEMS_NAME_DIVIDER%");
        printer.addText(`${el.count} - ${name[0]}`);
        printer.addTextPosition(450);
        printer.addText(`${el.total}\n`);
        el.item_name.split("%ITEMS_NAME_DIVIDER%").map((el1, i) => {
          if (i === 0) {
            return;
          }
          printer.addTextPosition(37);
          printer.addText(`${el1}\n`);
        });
        el.addon_items.map((el1, i) => {
          printer.addTextPosition(37);
          printer.addText(`1 - ${el1.addon_title} ${el1.addon_sub}\n`);
        });
        // printer.addTextPosition(470);
        // printer.addText("30.00\n");
        // printer.addText("2 - Skirt, Below Knee");
        // printer.addTextPosition(470);
        // printer.addText("40.00\n");
      });
      printer.addText("- - - - - - - - - - - -\n");
      printer.addText("SubTotal");
      printer.addTextPosition(450);
      printer.addText(`${page_data?.sub_total.toFixed(2)}\n`);
      printer.addText("Discount");
      printer.addTextPosition(450);
      printer.addText(`${(0).toFixed(2)}\n`);
      printer.addText("Total");
      printer.addTextPosition(450);
      printer.addText(`${page_data?.total}\n`);
      printer.addFeedUnit(100);

      printer.addCut(printer.CUT_FEED);
      printer.send();
    }
  };
  console.log(page_data);
  return (
    <div className="printorder">
      <div className="order_id">{page_data?.sql_data?.order_id}</div>
      <div className="order_name">{page_data?.sql_data?.client_name}</div>
      <div className="order_address">{page_data?.sql_data?.address}</div>
      <div className="order_time">
        {page_data?.sql_data?.order_date +
          " " +
          page_data?.sql_data?.order_time}
      </div>
      <div className="dotted_lines mb"></div>
      <div className="dotted_lines"></div>
      <div className="order_items">
        {page_data?.file_data?.order_item.map((el) => (
          <div className="items_group">
            <div className="name">
              <div className="main_item">
                <div>{el.count} -&nbsp;</div>
                <div>
                  {order_name(el.item_name.split("%ITEMS_NAME_DIVIDER%")).map(
                    (el1) => (
                      <div>{el1}</div>
                    )
                  )}
                </div>
              </div>
              <div className="addons_group">
                {el.addon_items.map((el1) => (
                  <div className="addon_item">
                    1 - {el1.addon_title + " " + el1.addon_sub}
                  </div>
                ))}
              </div>
            </div>
            <div className="total">{el.total}</div>
          </div>
        ))}
      </div>
      <div className="dotted_lines mbt"></div>
      <div className="total_group">
        <div className="sub_total">
          <div>SubTotal</div>
          <div>{Number(page_data?.sub_total).toFixed(2)}</div>
        </div>
        <div className="sub_total">
          <div>Discount</div>
          <div>
            {/* {(page_data?.file_data?.discount_switch ? "$ " : "") +
              page_data?.file_data?.globaldiscount +
              (page_data?.file_data?.discount_switch ? "" : " %")} */}
            {page_data.discount_dollars}
          </div>
        </div>
        <div className="final_total">
          <div>Total</div>
          <div>{page_data?.total}</div>
        </div>
      </div>
      <div className="print_group">
        <div
          className="btn print_btn"
          // style={{ maxWidth: "50vw", margin: "auto" }}
          type="type2"
          onClick={() => {
            window.print();
          }}
        >
          Print
        </div>
        {is_admin && current_store?.default?.printer_ip && (
          <div
            className="btn  thermal_printer"
            type="type2"
            onClick={() => {
              console.log(1);
              // print_Data();
              print_invoice_printer(
                {
                  data: { ...page_data, ip: current_store.default.printer_ip },
                },
                current_store.user.token,
                () => {}
              );
            }}
          >
            Tag Printer
          </div>
        )}
      </div>
      {/* <div className="printer_modal">
        <div className="main">
          <div className="header">
            <Close
              clickhandel={() => {
                // close();
              }}
            />
          </div>
          <div className="body">
            <div className="title">Add Printer IP</div>
            <div className="cust_inp">
              <input
                type="text"
                placeholder="Insert Name"
                onChange={({ target }) => {
                  // setteam_data((prev) => ({ ...prev, name: target.value }));
                }}
              />
            </div>
            <div className="title">Add Printer IP</div>
            <div className="cust_inp">
              <input
                type="text"
                placeholder="Insert Name"
                onChange={({ target }) => {
                  // setteam_data((prev) => ({ ...prev, name: target.value }));
                }}
              />
            </div>
            <div className="title">Add Printer Name</div>
            <div className="title">* printer data is stored locally</div>
          </div>
        </div>
      </div> */}
    </div>
  );
};
