import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Cash,
  Deliver,
  Eye,
  MenuDots,
  Pencil,
  Receipt,
  Refresh,
  Tick,
  Trash,
} from "../../Component/Common/Images/Images";
import { SearchAndFilterBar } from "../../Component/Common/SearchAndFilterBar/SearchAndFilterBar";
import { Table } from "../../Component/Common/Table/Table";
import { DraftOrdersFilterModal } from "../../Component/DraftOrders/FilterModal/DraftOrdersFilterModal";
import {
  cust_img_note,
  order_table,
  pricelist_short,
} from "../../Component/dummydata";
import edit_draftorder_table_item from "../../img/draftorder/edit.png";
import close_icon from "../../img/icons/white/x_icon.svg";
import axios from "axios";

import { AddOrder } from "../AddOrder/AddOrder";
import { TempDraftModal } from "./TempDraftModal/TempDraftModal";
import "./tempdraftordersStyle.scss";
import { TempCancelModal } from "./TempCancelModal/TempCancelModal";
import { TempDeliveryModal } from "./TempDeliveryModal/TempDeliveryModal";
import { base_url } from "../../API/data";
import { Loading } from "../../Component/Common/Loader/Loading";
import {
  complete_order,
  complete_pickup,
  createorder,
  getclient_byid,
  geteorderbyid,
  getorder,
  getorderbyid,
  mobile_convert_display,
} from "../../API/API";
import { FailedModal } from "./FailedModal/FailedModal";
import { DeliveryFailedModal } from "./DeliveryFailedModal/DeliveryFailedModal";

// const order_statuses = [
//   "All",
//   "Pickup Requested",
//   "Driver Enroute to Pickup",
//   "Picked Up",
//   "Customer Charged",
//   "Deliver Clothes",
//   "Driver Enroute to Dropoff",
//   "Order Cancelled",
//   "Order Completed",
// ];

export const  TempDraftOrdersTable = ({
  setshow_navbar,
  setdata,
  current_active,
  order_statuses,
  status,
  filter_date,
  setview_order,
  setloader,
  setorderpage_data,
  setclientmini_data,
  search,
  type,
  filter_category,
  setOrderDataList,
}) => {
  const current_store = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [show_this_page, setshow_this_page] = useState(false);
  const [status_failed_modal, setstatus_failed_modal] = useState("");
  // const [order_filter, setorder_filter] = useState(order_statuses[0]);
  const [original_table_data, setoriginal_table_data] = useState([
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]);
  const [current_table, setcurrent_table] = useState([
    // {
    //   "order_id": 29,
    //   "order_date": "2022-07-01T11:50:35.000Z",
    //   "amount": null,
    //   "delivered": "0",
    //   "charged": "0",
    //   "client_name": "admin app",
    //   "client_id": 8
    // }
  ]);

  useEffect(() => {
    setshow_navbar(true);
    setloader(true);
    // if (
    //   window.location.search == "" ||
    //   Number(window.location.search.split("=")[1]) - new Date().getTime() <
    //     -10000
    // ) {
    //   window.location.search = `?time=${new Date().getTime()}`;
    // }
    if (current_store.user.token == "") {
      let local_token = localStorage.getItem("jc_pos_token");
      if (local_token) {
        dispatch({ type: "login", payload: { token: local_token } });
      } else {
        navigate("/?time=" + new Date());
      }
    } else {
      getorder(current_store.user.token, (res) => {
        if (res.error) {
          localStorage.removeItem("jc_pos_token");
          return navigate("/");
        }
        setclientmini_data([...res.clients]);
        let temp_original_table = [[], [], [], [], [], [], [], [], [], [], []];
        // let [total_revenue, order_in_progress, waiting_for_pickup]
        let calculated_results = [0, 0, 0, 0, 0, 0];
        // let calculated_results = [total revenue, order in progress, waiting for pickup,0,0,0];
        res.clients.map((el) => {
          if (
            // new Date(el.created_at).getDate() === new Date().getDate() &&
            new Date(el.created_at).getMonth() === new Date().getMonth() &&
            new Date(el.created_at).getFullYear() === new Date().getFullYear()
          ) {
            calculated_results[5] += 1;
          }
        });
        res.orders.map((el) => {
          if (
            !(
              el.display_status === order_statuses[9] ||
              el.display_status === order_statuses[10]
            )
          ) {
            temp_original_table[0].push(el);
            calculated_results[1] += 1;
          }
          if (
            order_statuses.indexOf(el.display_status) !== -1 &&
            order_statuses.indexOf(el.display_status) <= 4
          ) {
            calculated_results[3] += 1;
          }
          if (
            new Date(el.order_date).getDate() === new Date().getDate() &&
            new Date(el.order_date).getMonth() === new Date().getMonth() &&
            new Date(el.order_date).getFullYear() === new Date().getFullYear()
          ) {
            calculated_results[4] += 1;
          }
          switch (el.display_status) {
            case order_statuses[1]:
              temp_original_table[1].push(el);
              calculated_results[2] += 1;
              break;
            case order_statuses[2]:
              temp_original_table[2].push(el);
              break;
            case order_statuses[3]:
              temp_original_table[3].push(el);
              break;
            case order_statuses[4]:
              temp_original_table[4].push(el);
              break;
            case order_statuses[5]:
              temp_original_table[5].push(el);
              break;
            case order_statuses[6]:
              temp_original_table[6].push(el);
              break;
            case order_statuses[7]:
              temp_original_table[7].push(el);
              break;
            case order_statuses[8]:
              temp_original_table[8].push(el);
              break;
            case order_statuses[9]:
              temp_original_table[9].push(el);
              break;
            case order_statuses[10]:
              temp_original_table[10].push(el);
              break;
          }
          if (
            el.amount &&
            Number(el.amount) > 0 &&
            el.display_status !== order_statuses[7]
          ) {
            calculated_results[0] += Number(el.amount);
          }
          // if()
        });

        setoriginal_table_data([
          ...temp_original_table,
          // [
          //   ...res.filter(
          //     (el) =>
          //       !(
          //         el.display_status === order_statuses[7] ||
          //         el.display_status === order_statuses[8]
          //       )
          //   ),
          // ],
          // [...res.filter((el) => el.display_status === order_statuses[1])],
          // [...res.filter((el) => el.display_status === order_statuses[2])],
          // [...res.filter((el) => el.display_status === order_statuses[3])],
          // [...res.filter((el) => el.display_status === order_statuses[4])],
          // [...res.filter((el) => el.display_status === order_statuses[5])],
          // [...res.filter((el) => el.display_status === order_statuses[6])],
          // [...res.filter((el) => el.display_status === order_statuses[7])],
          // [...res.filter((el) => el.display_status === order_statuses[8])],
        ]);
        setorderpage_data([...calculated_results]);
        setcurrent_table([...temp_original_table[0]]);
        setloader(false);
        setshow_this_page(true);
      });
    }
  }, [current_store.user.token, type]);

  const [order_selected, setorder_selected] = useState();
  const [filter_modal, setfilter_modal] = useState(false);
  const [cancel_modal, setcancel_modal] = useState(false);
  const [delivery_modal, setdelivery_modal] = useState(false);
  // const [current_active, setcurrent_active] = useState("0");
  useEffect(() => {
    // if (current_store.user.token != '' && (!filter_modal || !cancel_modal)) {
    //   setcurrent_table([])
    //   axios.post('https://api.juliettecleaners.com/api/pos/v1/get-orders', {
    //     "charge": current_active == 0 ? '0' : '1',
    //     "delivery": current_active == 2 ? '1' : '0'
    //   }, {
    //     headers: {
    //       Authorization: current_store.user.token
    //     }
    //   })
    //     .then(res => res.data)
    //     .then(res => {
    //       if (res.error) {
    //         localStorage.removeItem('jc_pos_token')
    //         return navigate('/')
    //       }
    //       setcurrent_table([...res])
    //     })
    // }
    // setcurrent_table([...])
    // if (current_active != 0) {
    if (status == 0) {
      switch (Number(current_active)) {
        case 0:
          setcurrent_table(original_table_data[0]);
          break;
        case 1:
          setcurrent_table(original_table_data[9]);
          break;
        case 2:
          setcurrent_table(original_table_data[10]);
          break;
        default:
          setcurrent_table(original_table_data[0]);
          break;
      }
    } else {
      switch (Number(status)) {
        // case 0:
        //   break;
        case 1:
          setcurrent_table(original_table_data[1]);
          break;
        case 2:
          setcurrent_table(original_table_data[2]);
          break;
        case 3:
          setcurrent_table(original_table_data[3]);
          break;
        case 4:
          setcurrent_table(original_table_data[4]);
          break;
        case 5:
          setcurrent_table(original_table_data[5]);
          break;
        case 6:
          setcurrent_table(original_table_data[6]);
          break;
        case 7:
          setcurrent_table(original_table_data[7]);
          break;
        case 8:
          setcurrent_table(original_table_data[8]);
          break;
        case 9:
          setcurrent_table(original_table_data[9]);
          break;
        case 10:
          setcurrent_table(original_table_data[10]);
          break;
        default:
          setcurrent_table(original_table_data[0]);
          break;
      }
    }
    // setorder_filter(order_statuses[0]);
    // }
    // if (current_active == 1) {
    //   // setcurrent_table()
    // }
    // if (original_table_data) {
    // setcurrent_table([...original_table_data[8]]);
    // }
  }, [current_active, status]);

  // useEffect(() => {}, []);

  const [filter_options, setfilter_options] = useState({
    date: "",
    order_type_title: "",
    order_type_body: "",
  });

  useEffect(() => {
    setOrderDataList(current_table);
  }, [current_table]);

  return (
    <>
      {!show_this_page && <Loading />}
      <>
        {/* {!change_page_to_add_order ? ( */}
        <div className="draftorder">
          <div className="draftorder_body">
            {/* <SearchAndFilterBar
            placeholder="Search for Name, Description, Date"
            change_search={search}
            handlechange_search={(value) => setsearch(value)}
            handleclick_filter={setfilter_modal}
            filter_show='no'
          /> */}
            {/* 
            0 = charge:0,delivery:0 
            1 = charge:1,delivery:0 
            2 = charge:1,delivery:1 
          */}
            {/* <div className="draftmenu">
              <div
                className={current_active == 0 ? "active" : ""}
                onClick={() => {
                  setcurrent_active(0);
                  setcurrent_table(original_table_data[0]);
                }}
              >
                ORDERS
              </div>
              <div
                className={current_active == 1 ? "active" : ""}
                onClick={() => {
                  setcurrent_active(1);
                  setcurrent_table(original_table_data[7]);
                }}
              >
                CANCELLED ORDER
              </div>
              <div
                className={current_active == 2 ? "active" : ""}
                onClick={() => {
                  setcurrent_active(2);
                  setcurrent_table(original_table_data[8]);
                }}
              >
                COMPLETED ORDER
              </div>
            </div> */}
            {/* <div className="filter_btn_group">
              {filter_options.date !== "" && (
                <div className="filter_card">
                  {filter_options.date}
                  <img
                    src={close_icon}
                    onClick={() => {
                      setfilter_options({
                        ...filter_options,
                        date: "",
                      });
                    }}
                  />
                </div>
              )}
              {filter_options.order_type_title !== "" && (
                <div className="filter_card">
                  <div>
                    {pricelist_short[filter_options.order_type_title].title}
                    {filter_options.order_type_body !== "" && (
                      <>
                        {` - ${
                          pricelist_short[filter_options.order_type_title].body[
                            filter_options.order_type_body
                          ]
                        }`}
                      </>
                    )}
                  </div>
                  <img
                    src={close_icon}
                    onClick={() => {
                      setfilter_options({
                        ...filter_options,
                        order_type_title: "",
                        order_type_body: "",
                      });
                    }}
                  />
                </div>
              )}
            </div> */}
            {/* {current_active == 0 && (
              <div className="order_states">
                {order_statuses.map((el, i) => {
                  if (i <= 6) {
                    return (
                      <div
                        className={order_filter === el ? "active" : ""}
                        onClick={() => {
                          setorder_filter(el);
                          setcurrent_table(original_table_data[i]);
                        }}
                      >
                        {el}
                      </div>
                    );
                  }
                })}
              </div>
            )} */}
            {/* <div></div> */}
            {current_table.length == 0 ? (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "25px",
                  padding: "150px 0 0 0",
                  fontWeight: "900",
                }}
              >
                No Orders
              </div>
            ) : type == "report" ? (
              // "reports_table"
              <Table
                cust_class={"draftorder_table reports_table"}
                type="type1"
                table_head_data={[
                  "NAME",
                  "Order created",
                  "Payment status",
                  "Status",
                  "Order number",
                  "",
                ]}
                table_body_data={[
                  ...current_table
                    .filter((el) => {
                      return el.client_name
                        .toLowerCase()
                        .match(search.toLowerCase());
                    })
                    .filter((el) => {
                      if (type === "report") {
                        if (
                          // (el.items === null || el.items.items.length === 0) &&
                          filter_category === ""
                        ) {
                          return true;
                        } else {
                          let temp_items = el.items ? el.items.items : [];
                          let temp_filter_category =
                            filter_category.split(" > ");
                          if (
                            temp_filter_category[
                              temp_filter_category.length - 1
                            ] == ""
                          ) {
                            temp_filter_category.length =
                              temp_filter_category.length - 1;
                          }
                          temp_filter_category = temp_filter_category.join(
                            "%ITEMS_NAME_DIVIDER%"
                          );

                          return temp_items
                            .map(
                              (el) =>
                                !!el
                                  .toLowerCase()
                                  .match(temp_filter_category.toLowerCase())
                            )
                            .includes(true);
                          // temp_items
                          //   .map((el) => el.split())
                          //   .toLowerCase()
                          //   .match(search.toLowerCase());
                          return true;
                        }
                      } else {
                        return true;
                      }
                    })
                    .filter((el) => {
                      if (!filter_date) {
                        return true;
                      }
                      let el_temp_date = el.order_date.split(" ");
                      let filter_temp_date = filter_date.split(" ");

                      return (
                        el_temp_date[0] == filter_temp_date[1] &&
                        el_temp_date[1].slice(0, -1) == filter_temp_date[0] &&
                        el_temp_date[2] == filter_temp_date[2]
                      );
                    })
                    .map((el) => {
                      if (el.add_order_data) return [];
                      return [
                        el.client_name,
                        <>
                          <div>{el.order_date}</div>
                          <div>{el.order_time}</div>
                        </>,
                        el.amount ? (
                          <span style={{ color: "var(--green)" }}>Paid</span>
                        ) : (
                          <span style={{ color: "var(--red)" }}>Un Paid</span>
                        ),
                        el.display_status,
                        el.order_id,
                        // el.items ? el.items.items.join("") : "no items",
                        // el.amount ? `$ ${el.amount}` : "-",
                        // shortId
                        // el.shortId || "-",

                        [
                          "Driver Enroute to Dropoff",
                          // "Delivery Failed",
                          "Order Completed",
                          "Order Cancelled",
                        ].indexOf(el.display_status) != -1 ? (
                          <Eye
                            clickhandel={() => {
                              getorderbyid(
                                el.order_id,
                                current_store.user.token,
                                (data) => {
                                  getclient_byid(
                                    data.data.sql_data.client_id,
                                    current_store.user.token,
                                    (data1) => {
                                      // add date to status_order_data w.r.t below array
                                      const order_statuses = [
                                        // "All",
                                        "Pickup Requested",
                                        "Driver Enroute to Pickup",
                                        "Picked Up",
                                        "Customer Charged",
                                        "Deliver Clothes",
                                        "Driver Enroute to Dropoff",
                                        // "Order Cancelled",
                                        "Order Completed",
                                      ];
                                      // return;
                                      setview_order({
                                        order_id: data.data.sql_data.order_id,
                                        notes: data.data.sql_data.notes,
                                        name: data.data.sql_data.client_name,
                                        address: data.data.sql_data.address,
                                        phone:
                                          // data.data.sql_data.mobile ||
                                          mobile_convert_display(
                                            data.data.sql_data.mobile
                                          ),
                                        payment: data.data.sql_data.last_4,
                                        status_order_data: [
                                          ...data.data.status_logs,
                                          // null,
                                        ],
                                        // status_order_data: [],
                                        order_created:
                                          data.data.sql_data.order_date,
                                        card_list: data1.data.cards,
                                        client_id: data.data.sql_data.client_id,
                                        client_particulars: data.data.sql_data
                                          .client_particulars || {
                                          others: "0",
                                          laundry: "0",
                                          drycleaning: "0",
                                        },
                                        file_data: {
                                          ...(["undefined", ""].indexOf(
                                            data.data.file_data
                                          ) != -1
                                            ? {}
                                            : JSON.parse(data.data.file_data)),
                                        },
                                      });
                                      setloader(false);
                                      navigate("/view-order");
                                    }
                                  );
                                }
                              );
                              setcancel_modal(true);
                              setorder_selected(el);
                            }}
                          />
                        ) : (
                          ""
                        ),
                      ];
                    }),
                ]}
              />
            ) : (
              <Table
                cust_class={"draftorder_table "}
                type="type1"
                table_head_data={[
                  "ID",
                  "NAME",
                  "Cost",
                  "Status",
                  "OnFleet Status",
                  "Created on",
                  "",
                ]}
                table_body_data={[
                  ...current_table
                    .filter((el) => {
                      return el.client_name
                        .toLowerCase()
                        .match(search.toLowerCase());
                    })
                    .filter((el) => {
                      if (!filter_date) {
                        return true;
                      }
                      let el_temp_date = el.order_date.split(" ");
                      let filter_temp_date = filter_date.split(" ");

                      return (
                        el_temp_date[0] == filter_temp_date[1] &&
                        el_temp_date[1].slice(0, -1) == filter_temp_date[0] &&
                        el_temp_date[2] == filter_temp_date[2]
                      );
                    })
                    .map((el) => {
                      if (el.add_order_data) return [];
                      return [
                        el.order_id,
                        el.client_name,
                        el.amount ? `$ ${el.amount}` : "-",
                        el.display_status,
                        // shortId
                        el.shortId || "-",
                        <>
                          <div>
                            {el.order_date}</div>
                          
                          <div>{el.order_time}</div>

                          {/* {new Date(el.order_date)
                      .toString()
                      .split(" ")
                      .splice(1, 3)
                    .join(" ")} */}
                          <div
                            style={{
                              marginLeft: "auto",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          ></div>
                        </>,
                        <>
                          {type === "report" ? (
                            <>
                              {[
                                "Driver Enroute to Dropoff",
                                // "Delivery Failed",
                                "Order Completed",
                                "Order Cancelled",
                              ].indexOf(el.display_status) != -1 ? (
                                <Eye
                                  clickhandel={() => {
                                    getorderbyid(
                                      el.order_id,
                                      current_store.user.token,
                                      (data) => {
                                        getclient_byid(
                                          data.data.sql_data.client_id,
                                          current_store.user.token,
                                          (data1) => {
                                            // add date to status_order_data w.r.t below array
                                            const order_statuses = [
                                              // "All",
                                              "Pickup Requested",
                                              "Driver Enroute to Pickup",
                                              "Picked Up",
                                              "Customer Charged",
                                              "Deliver Clothes",
                                              "Driver Enroute to Dropoff",
                                              // "Order Cancelled",
                                              "Order Completed",
                                            ];
                                            // return;

                                            setview_order({
                                              order_id:
                                                data.data.sql_data.order_id,
                                              notes:
                                                data.data.sql_data.notes || "",
                                              name: data.data.sql_data
                                                .client_name,
                                              address:
                                                data.data.sql_data.address,
                                              phone:
                                                // data.data.sql_data.mobile ||
                                                mobile_convert_display(
                                                  data.data.sql_data.mobile
                                                ),
                                              payment:
                                                data.data.sql_data.last_4,
                                              status_order_data: [
                                                ...data.data.status_logs,
                                                // null,
                                              ],
                                              // status_order_data: [],
                                              order_created:
                                                data.data.sql_data.order_date,
                                              card_list: data1.data.cards,
                                              client_id:
                                                data.data.sql_data.client_id,
                                              client_particulars: data.data
                                                .sql_data
                                                .client_particulars || {
                                                others: "0",
                                                laundry: "0",
                                                drycleaning: "0",
                                              },
                                              file_data: {
                                                ...(["undefined", ""].indexOf(
                                                  data.data.file_data
                                                ) != -1
                                                  ? {}
                                                  : JSON.parse(
                                                      data.data.file_data
                                                    )),
                                              },
                                            });
                                            setloader(false);
                                            navigate("/view-order");
                                          }
                                        );
                                      }
                                    );
                                    // setcancel_modal(true);
                                    // setorder_selected(el);
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            <>
                              {[
                                // "Pickup Requested",
                                "Picked Up",
                                // "Customer Charged",
                                "Deliver Clothes",
                              ].indexOf(el.display_status) != -1 && (
                                <>
                                  <Pencil
                                    custStyle={{
                                      marginRight: "20px",
                                    }}
                                    clickhandel={() => {
                                      // createorder(
                                      //   "test id",
                                      //   current_store.user.token,
                                      //   () => {}
                                      // );
                                      setloader(true);
                                      getorderbyid(
                                        el.order_id,
                                        current_store.user.token,
                                        (data) => {
                                          getclient_byid(
                                            data.data.sql_data.client_id,
                                            current_store.user.token,
                                            (data1) => {
                                              // add date to status_order_data w.r.t below array
                                              const order_statuses = [
                                                // "All",
                                                "Pickup Requested",
                                                "Driver Enroute to Pickup",
                                                "Picked Up",
                                                "Customer Charged",
                                                "Deliver Clothes",
                                                "Driver Enroute to Dropoff",
                                                // "Order Cancelled",
                                                "Order Completed",
                                              ];
                                              // return;

                                              setdata({
                                                order_id:
                                                  data.data.sql_data.order_id,
                                                notes:
                                                  data.data.sql_data.notes ||
                                                  "",
                                                name: data.data.sql_data
                                                  .client_name,
                                                address:
                                                  // "",
                                                  data.data.sql_data.address,
                                                phone:
                                                  // data.data.sql_data.mobile ||
                                                  mobile_convert_display(
                                                    data.data.sql_data.mobile
                                                  ),
                                                payment:
                                                  data.data.sql_data.last_4,
                                                status_order_data: [
                                                  ...data.data.status_logs,
                                                  // null,
                                                ],
                                                // status_order_data: [],
                                                order_created:
                                                  data.data.sql_data.order_date,
                                                card_list: data1.data.cards,
                                                client_id:
                                                  data.data.sql_data.client_id,
                                                charged:
                                                  data.data.sql_data.charged,
                                                delivered:
                                                  data.data.sql_data.delivered,
                                                cust_img_note: {
                                                  img: [
                                                    ...data.data.sql_data.images.map(
                                                      (el) => el.url
                                                    ),
                                                  ],
                                                  notes:
                                                    data.data.sql_data.notes ||
                                                    "",
                                                },
                                                discount_code:
                                                  data.data.sql_data
                                                    .discount_code || null,
                                                client_particulars: data.data
                                                  .sql_data
                                                  .client_particulars || {
                                                  others: "0",
                                                  laundry: "0",
                                                  drycleaning: "0",
                                                },
                                                file_data: {
                                                  ...(["undefined", ""].indexOf(
                                                    data.data.file_data
                                                  ) != -1
                                                    ? {}
                                                    : JSON.parse(
                                                        data.data.file_data
                                                      )),
                                                },
                                              });
                                              setloader(false);
                                              navigate("/add-order");
                                            }
                                          );
                                        }
                                      );
                                    }}
                                    className="table_action"
                                  />
                                </>
                              )}
                              {/* {el.display_status == "Customer Charged" && (
                          // (el.charged == 1 && el.delivered == 0 )&&
                          <Deliver
                            clickhandel={() => {
                              setdelivery_modal(true);
                              setorder_selected(el);
                            }}
                          />
                        )} */}
                              {[
                                "Order Cancelled",
                                "Order Completed",
                                "Pickup Failed",
                                "Delivery Failed",
                                "Customer Charged",
                                "Deliver Clothes",
                                "Driver Enroute to Dropoff",
                                "",
                              ].indexOf(el.display_status) != -1 ? (
                                ""
                              ) : (
                                <Trash
                                  clickhandel={() => {
                                    setcancel_modal(true);
                                    setorder_selected(el);
                                  }}
                                />
                              )}
                              {[
                                "Driver Enroute to Dropoff",
                                // "Delivery Failed",
                                "Order Completed",
                                // "Order Cancelled",
                              ].indexOf(el.display_status) != -1 ? (
                                <Receipt
                                  clickhandel={() => {
                                    console.log("show receipt", el);
                                    navigate("/receipt/" + el.order_id);

                                    // window.open(
                                    //   "https://admin.juliettecleaners.com/receipt/" +
                                    //     el.order_id,
                                    //   "_blank"
                                    // );
                                  }}
                                />
                              ) : (
                                ""
                              )}
                              {[
                                "Driver Enroute to Dropoff",
                                // "Delivery Failed",
                                "Order Completed",
                                "Order Cancelled",
                              ].indexOf(el.display_status) != -1 ? (
                                <Eye
                                  clickhandel={() => {
                                    getorderbyid(
                                      el.order_id,
                                      current_store.user.token,
                                      (data) => {
                                        getclient_byid(
                                          data.data.sql_data.client_id,
                                          current_store.user.token,
                                          (data1) => {
                                            // add date to status_order_data w.r.t below array
                                            const order_statuses = [
                                              // "All",
                                              "Pickup Requested",
                                              "Driver Enroute to Pickup",
                                              "Picked Up",
                                              "Customer Charged",
                                              "Deliver Clothes",
                                              "Driver Enroute to Dropoff",
                                              // "Order Cancelled",
                                              "Order Completed",
                                            ];
                                            // return;

                                            setview_order({
                                              order_id:
                                                data.data.sql_data.order_id,
                                              notes:
                                                data.data.sql_data.notes || "",
                                              name: data.data.sql_data
                                                .client_name,
                                              address:
                                                data.data.sql_data.address,
                                              phone:
                                                // data.data.sql_data.mobile ||
                                                mobile_convert_display(
                                                  data.data.sql_data.mobile
                                                ),
                                              payment:
                                                data.data.sql_data.last_4,
                                              status_order_data: [
                                                ...data.data.status_logs,
                                                // null,
                                              ],
                                              // status_order_data: [],
                                              order_created:
                                                data.data.sql_data.order_date,
                                              card_list: data1.data.cards,
                                              client_id:
                                                data.data.sql_data.client_id,
                                              file_data: {
                                                ...(["undefined", ""].indexOf(
                                                  data.data.file_data
                                                ) != -1
                                                  ? {}
                                                  : JSON.parse(
                                                      data.data.file_data
                                                    )),
                                              },
                                            });
                                            setloader(false);
                                            navigate("/view-order");
                                          }
                                        );
                                      }
                                    );
                                    // setcancel_modal(true);
                                    // setorder_selected(el);
                                  }}
                                />
                              ) : (
                                ""
                              )}
                              {(el.display_status == "Pickup Failed" ||
                                el.display_status == "Delivery Failed") && (
                                <>
                                  <MenuDots
                                    clickhandel={() =>
                                      setstatus_failed_modal(el.order_id)
                                    }
                                  />
                                  {/* {el.order_id} */}
                                  {status_failed_modal === el.order_id && (
                                    <>
                                      {el.display_status == "Pickup Failed" && (
                                        <FailedModal
                                          close={() =>
                                            setstatus_failed_modal("")
                                          }
                                          to_show={[
                                            {
                                              ele: <Refresh />,
                                              name: "Re-start Pickup",
                                              handel: () => {
                                                let completeorder_obj = {
                                                  order_id: el.order_id,
                                                  // client_id: el.client_id,
                                                  create_onfleet: true,
                                                };

                                                complete_pickup(
                                                  { ...completeorder_obj },
                                                  current_store.user.token,
                                                  (data) => {
                                                    if (!data.status) {
                                                      return navigate("/");
                                                    }

                                                    window.location.reload();
                                                    return navigate("/orders");
                                                  }
                                                );
                                              },
                                            },
                                            {
                                              ele: <Tick color={2} />,
                                              name: "Complete Pickup",
                                              handel: () => {
                                                let completeorder_obj = {
                                                  order_id: el.order_id,
                                                  // client_id: el.client_id,
                                                  create_onfleet: false,
                                                };

                                                complete_pickup(
                                                  { ...completeorder_obj },
                                                  current_store.user.token,
                                                  (data) => {
                                                    if (!data.status) {
                                                      return navigate("/");
                                                    }

                                                    window.location.reload();
                                                    return navigate("/orders");
                                                  }
                                                );
                                              },
                                            },
                                            {
                                              ele: <Trash />,
                                              name: "Delete",
                                              handel: () => {
                                                setcancel_modal(true);
                                                setorder_selected(el);
                                              },
                                            },
                                          ]}
                                        />
                                      )}
                                      {el.display_status ==
                                        "Delivery Failed" && (
                                        <FailedModal
                                          close={() =>
                                            setstatus_failed_modal("")
                                          }
                                          to_show={[
                                            {
                                              ele: <Refresh />,
                                              name: "Re-start Delievery",
                                              handel: () => {
                                                let completeorder_obj = {
                                                  order_id: el.order_id,
                                                  // client_id: el.client_id,
                                                  create_onfleet: true,
                                                };

                                                complete_order(
                                                  { ...completeorder_obj },
                                                  current_store.user.token,
                                                  (data) => {
                                                    if (!data.status) {
                                                      return navigate("/");
                                                    }

                                                    window.location.reload();
                                                    return navigate("/orders");
                                                  }
                                                );
                                              },
                                            },
                                            {
                                              ele: <Tick color={2} />,
                                              name: "Complete Delivery",
                                              handel: () => {
                                                let completeorder_obj = {
                                                  order_id: el.order_id,
                                                  // client_id: el.client_id,
                                                  create_onfleet: false,
                                                };

                                                complete_order(
                                                  { ...completeorder_obj },
                                                  current_store.user.token,
                                                  (data) => {
                                                    if (!data.status) {
                                                      return navigate("/");
                                                    }

                                                    window.location.reload();

                                                    return navigate("/orders");
                                                  }
                                                );
                                              },
                                            },
                                            {
                                              ele: <Trash />,
                                              name: "Delete",
                                              handel: () => {
                                                setcancel_modal(true);
                                                setorder_selected(el);
                                              },
                                            },
                                          ]}
                                        />
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                              {[
                                "Customer Charged",
                                "Deliver Clothes",
                                "Driver Enroute to Dropoff",
                              ].includes(el.display_status) && (
                                <>
                                  <MenuDots
                                    clickhandel={() =>
                                      setstatus_failed_modal(el.order_id)
                                    }
                                  />
                                  {/* {el.order_id} */}
                                  {status_failed_modal === el.order_id && (
                                    <>
                                      {/* {el.display_status == "Pickup Failed" && ( */}
                                      <FailedModal
                                        close={() => setstatus_failed_modal("")}
                                        to_show={[
                                          {
                                            ele: <Receipt />,
                                            name: "Receipt",
                                            handel: () => {
                                              console.log("show receipt", el);
                                              navigate(
                                                "/receipt/" + el.order_id
                                              );

                                              // window.open(
                                              //   "https://admin.juliettecleaners.com/receipt/" +
                                              //     el.order_id,
                                              //   "_blank"
                                              // );
                                            },
                                          },
                                          {
                                            ele: <Pencil />,
                                            name: "Edit",
                                            handel: () => {
                                              setloader(true);
                                              getorderbyid(
                                                el.order_id,
                                                current_store.user.token,
                                                (data) => {
                                                  getclient_byid(
                                                    data.data.sql_data
                                                      .client_id,
                                                    current_store.user.token,
                                                    (data1) => {
                                                      // add date to status_order_data w.r.t below array
                                                      const order_statuses = [
                                                        // "All",
                                                        "Pickup Requested",
                                                        "Driver Enroute to Pickup",
                                                        "Picked Up",
                                                        "Customer Charged",
                                                        "Deliver Clothes",
                                                        "Driver Enroute to Dropoff",
                                                        // "Order Cancelled",
                                                        "Order Completed",
                                                      ];
                                                      // return;

                                                      setdata({
                                                        order_id:
                                                          data.data.sql_data
                                                            .order_id,
                                                        notes:
                                                          data.data.sql_data
                                                            .notes || "",
                                                        name: data.data.sql_data
                                                          .client_name,
                                                        address:
                                                          // "",
                                                          data.data.sql_data
                                                            .address,
                                                        phone:
                                                          // data.data.sql_data.mobile ||
                                                          mobile_convert_display(
                                                            data.data.sql_data
                                                              .mobile
                                                          ),
                                                        payment:
                                                          data.data.sql_data
                                                            .last_4,
                                                        status_order_data: [
                                                          ...data.data
                                                            .status_logs,
                                                          // null,
                                                        ],
                                                        // status_order_data: [],
                                                        order_created:
                                                          data.data.sql_data
                                                            .order_date,
                                                        card_list:
                                                          data1.data.cards,
                                                        client_id:
                                                          data.data.sql_data
                                                            .client_id,
                                                        charged:
                                                          data.data.sql_data
                                                            .charged,
                                                        delivered:
                                                          data.data.sql_data
                                                            .delivered,
                                                        cust_img_note: {
                                                          img: [
                                                            ...data.data.sql_data.images.map(
                                                              (el) => el.url
                                                            ),
                                                          ],
                                                          notes:
                                                            data.data.sql_data
                                                              .notes || "",
                                                        },
                                                        discount_code:
                                                          data.data.sql_data
                                                            .discount_code ||
                                                          null,
                                                        client_particulars: data
                                                          .data.sql_data
                                                          .client_particulars || {
                                                          others: "0",
                                                          laundry: "0",
                                                          drycleaning: "0",
                                                        },
                                                        file_data: {
                                                          ...([
                                                            "undefined",
                                                            "",
                                                          ].indexOf(
                                                            data.data.file_data
                                                          ) != -1
                                                            ? {}
                                                            : JSON.parse(
                                                                data.data
                                                                  .file_data
                                                              )),
                                                        },
                                                      });
                                                      setloader(false);
                                                      navigate("/add-order");
                                                    }
                                                  );
                                                }
                                              );
                                            },
                                          },
                                          {
                                            ele: <Trash />,
                                            name: "Delete",
                                            handel: () => {
                                              setcancel_modal(true);
                                              setorder_selected(el);
                                            },
                                          },
                                        ]}
                                      />
                                      {/* )} */}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>,
                      ];
                    }),
                ]}
              />
            )}
          </div>
        </div>
        {filter_modal && (
          <TempDraftModal
            order_selected={order_selected}
            cancel={() => {
              setfilter_modal(false);
            }}
          />
        )}
        {cancel_modal && type !== "report" && (
          <TempCancelModal
            order_selected={order_selected}
            cancel={() => {
              setcancel_modal(false);
            }}
          />
        )}
        {delivery_modal && type !== "report" && (
          <TempDeliveryModal
            order_selected={order_selected}
            cancel={() => {
              setdelivery_modal(false);
            }}
          />
        )}
      </>
    </>
  );
};
