import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./addorderStyle.scss";

import { PricelistAccordian } from "../../Component/AddOrders/PricelistAccordian/PricelistAccordian";
import { AddOrderTags } from "../../Component/AddOrders/AddOrderTags/AddOrderTags";
import { AddOrderImages } from "../../Component/AddOrders/AddOrderImages/AddOrderImages";
import { AddOrderStatus } from "../../Component/AddOrders/AddOrderStatus/AddOrderStatus";

import {
  TrashIconBlack,
  TrashIconWhite,
} from "../../Component/Common/Images/Images";
import {
  getclient_byid,
  getitems,
  getorderbyid,
  get_discount,
  mobile_convert_display,
  putorder,
} from "../../API/API";
import { useSelector } from "react-redux";
export const AddOrder = ({
  data,
  setdata,
  setshow_navbar,
  cust_img_note,
  loader,
  setloader,
}) => {
  // data = {
  //   ...data,
  //   discount_code: "test 1",
  // };
  // useEffect(() => {
  //   data = {
  //     ...data,
  //     discount_code: "test 1",
  //   };
  // }, []);
  // console.log(data);
  const current_store = useSelector((state) => state);

  const navigate = useNavigate();
  const [pricelist, setpricelist] = useState([]);
  const [discountlist_og, setdiscountlist_og] = useState([
    // {
    //   id: 0,
    //   discount_txt: "disc 1 (dry cleaning all)",
    //   is_active: true,
    //   is_deleted: false,
    //   is_for: {
    //     main: ['Dry Cleaning'],
    //     sub_main: [],
    //     items: [],
    //   },
    //   amount: "10",
    //   is_percentage: false,
    //   desc: "dry cleaning all",
    // },
    // {
    //   id: 1,
    //   discount_txt: "disc 2 (dry cleaning and alterations)",
    //   is_active: true,
    //   is_deleted: false,
    //   is_for: {
    //     main: ["Alterations", "Dry Cleaning"],
    //     sub_main: [],
    //     items: [],
    //   },
    //   amount: "10",
    //   is_percentage: true,
    //   desc: "dry cleaning and alterations",
    // },
  ]);
  const [discountlist_current, setdiscountlist_current] = useState({
    discount_value: [],
    show_discount: false,
  });
  // const [refresh_btn, setrefresh_btn] = useState(false);f$
  const [submit_btn, setsubmit_btn] = useState(false);
  const [pricelist_subsection, setpricelist_subsection] = useState([]);
  useEffect(() => {
    setshow_navbar(true);
    if (!data.order_id) {
      navigate("/orders");
      return;
    }
    get_discount({}, current_store.user.token, (discount_data) => {
      let order_data = { ...data };
      setloader(true);

      setdiscountlist_og([
        ...discount_data.data.filter((el) => el.is_active === true),
      ]);
      getitems({}, current_store.user.token, (data) => {
        let temp_arr = [
          ...data
            .filter(
              (el) => el.delete_status !== 1
              // && el.show_client_status !== 1
            )
            .map((el) => {
              if (el.has_child) {
                return {
                  title: el.name,
                  haschild: el.has_child,
                  is_addon: el.is_addon,
                  child: [
                    ...el.items
                      .filter(
                        (el) => el.delete_status !== 1
                        //  && el.show_client_status !== 1
                      )
                      .map((el1) => {
                        return {
                          acc_title: el1.name,
                          acc_body: [
                            ...el1.items
                              .filter((el) => el.delete_status !== 1)
                              .map((el) => {
                                return [
                                  el.name,
                                  Number(el.cost).toFixed(2).toString(),
                                ];
                              }),
                          ],
                        };
                      }),
                  ],
                };
              }
              if (el.special_cost) {
                return {
                  title: el.name,
                  haschild: false,
                  special_cost: true,
                  is_addon: el.is_addon,
                  acc_body: [
                    ...el.items
                      .filter((el) => el.delete_status !== 1)
                      .map((el1) => {
                        return [
                          el1.name,
                          `${Number(el1.cost.min_cost)
                            .toFixed(2)
                            .toString()} for ${el1.cost.min_weight}lb`,
                          `${Number(el1.cost.per_cost).toFixed(2)} per lb`,
                        ];
                      }),
                  ],
                };
              }
              return {
                title: el.name,
                haschild: false,
                is_addon: el.is_addon,
                acc_body: [
                  ...el.items
                    .filter((el) => el.delete_status !== 1)
                    .map((el1) => {
                      return [el1.name, Number(el1.cost).toFixed(2).toString()];
                    }),
                ],
                // ...el,
              };
            }),
        ];
        setpricelist([...temp_arr]);
        setpricelist_subsection([
          ...temp_arr.filter((el) => {
            return el.is_addon === 1;
          }),
        ]);
        let temp_discountlist = discount_data.data
          .filter((el) => el.is_active === true)
          .filter(
            (el) => {
              return el.discount_txt === order_data.discount_code;
            }
            // (el) => el.discount_txt === data.discount_code || "test 1"
          );
        if (temp_discountlist.length > 0) {
          let form_discount_data = {
            discount_on: "",
            show_discount: true,
          };
          temp_discountlist = {
            ...temp_discountlist[0],
          };
          if (temp_discountlist.is_for.main.length > 1) {
            form_discount_data = {
              show_discount: data
                .filter(
                  (el) => temp_discountlist.is_for.main.indexOf(el.id) !== -1
                )
                .map((el) => el.name),
            };
          } else if (temp_discountlist.is_for.main.length == 1) {
            form_discount_data = {
              show_discount: data.filter(
                (el) => temp_discountlist.is_for.main.indexOf(el.id) !== -1
              ),
            };
            if (form_discount_data.show_discount[0].has_child) {
              let parent_name = form_discount_data.show_discount.map(
                (el) => el.name
              );
              if (temp_discountlist.is_for.sub_main.length > 1) {
                form_discount_data = {
                  show_discount: form_discount_data.show_discount[0].items
                    .filter(
                      (el) =>
                        temp_discountlist.is_for.sub_main.indexOf(el.id) !== -1
                    )
                    .map(
                      (el) => parent_name + "%ITEMS_NAME_DIVIDER%" + el.name
                    ),
                };
              } else if (temp_discountlist.is_for.sub_main.length === 1) {
                form_discount_data = {
                  show_discount:
                    form_discount_data.show_discount[0].items.filter(
                      (el) =>
                        temp_discountlist.is_for.sub_main.indexOf(el.id) !== -1
                    ),
                };
                let submain_parent_name =
                  form_discount_data.show_discount[0].name;
                if (temp_discountlist.is_for.items.length > 1) {
                  form_discount_data = {
                    show_discount: form_discount_data.show_discount[0].items
                      .filter(
                        (el) =>
                          temp_discountlist.is_for.items.indexOf(el.item_id) !==
                          -1
                      )
                      .map(
                        (el) =>
                          parent_name +
                          "%ITEMS_NAME_DIVIDER%" +
                          submain_parent_name +
                          "%ITEMS_NAME_DIVIDER%" +
                          el.name
                      ),
                  };
                } else if (temp_discountlist.is_for.items.length === 1) {
                  form_discount_data = {
                    show_discount: form_discount_data.show_discount[0].items
                      .filter(
                        (el) =>
                          temp_discountlist.is_for.items.indexOf(el.item_id) !==
                          -1
                      )
                      .map(
                        (el) =>
                          parent_name +
                          "%ITEMS_NAME_DIVIDER%" +
                          submain_parent_name +
                          "%ITEMS_NAME_DIVIDER%" +
                          el.name
                      ),
                  };
                } else {
                  form_discount_data = {
                    show_discount: [
                      parent_name +
                        "%ITEMS_NAME_DIVIDER%" +
                        submain_parent_name,
                    ],
                  };
                }
              } else {
                form_discount_data = {
                  show_discount: parent_name,
                };
              }
            } else {
              let parent_name = form_discount_data.show_discount[0].name;
              if (temp_discountlist.is_for.items.length > 1) {
                form_discount_data = {
                  show_discount: form_discount_data.show_discount[0].items
                    .filter(
                      (el) =>
                        temp_discountlist.is_for.items.indexOf(el.item_id) !==
                        -1
                    )
                    .map(
                      (el) => parent_name + "%ITEMS_NAME_DIVIDER%" + el.name
                    ),
                };
              } else if (temp_discountlist.is_for.items.length === 1) {
                form_discount_data = {
                  show_discount: form_discount_data.show_discount[0].items
                    .filter(
                      (el) =>
                        temp_discountlist.is_for.items.indexOf(el.item_id) !==
                        -1
                    )
                    .map(
                      (el) => parent_name + "%ITEMS_NAME_DIVIDER%" + el.name
                    ),
                };
              } else {
                form_discount_data = {
                  show_discount: [parent_name],
                };
              }
            }
          } else {
            form_discount_data = {
              show_discount: data
                .filter((el) => el.delete_status !== 1)
                .map((el) => el.name),
            };
          }
          setdiscountlist_current({
            ...temp_discountlist,
            discount_value:
              form_discount_data.show_discount.length > 0
                ? [...form_discount_data.show_discount]
                : form_discount_data.show_discount,
          });
        } else {
          temp_discountlist = {
            ...temp_discountlist,
            discount_value: [],
          };
          setdiscountlist_current({
            ...temp_discountlist,
          });
        }
        // setdiscountlist_current({
        //   discount_txt: "disc 1 (dry cleaning all)",
        //   is_active: true,
        //   is_deleted: false,
        //   is_for: {
        //     main: ["Dry Cleaning"],
        //     sub_main: [],
        //     items: [],
        //   },
        //   amount: "10",
        //   is_percentage: false,
        //   desc: "dry cleaning all",
        // });
        setloader(false);
      });
    });
  }, [data]);
  const [payment_details, setpayment_details] = useState({
    failed_cards: [],
    payed_as: {},
    payed: false,
  });
  const [add_order_current_showing, setadd_order_current_showing] = useState(
    "add_order_menu_items"
  );
  // items - start
  const [order_item_cards, setorder_item_cards] = useState([
    // { item_name: "a", item_price: "12" },
    // { item_name: "b", item_price: "12" },
    // { item_name: "c", item_price: "12" },
  ]);
  // console.log("order_item_cards", order_item_cards);
  const [sub_total, setsub_total] = useState(0);
  const [item_delete_modal, setitem_delete_modal] = useState({});

  let calc_addon_subtotal = (arr) => {
    if (arr === undefined) {
      return 0;
    }
    if (arr.length === 0) {
      return 0;
    }
    let sum = 0;
    arr.map((el) => (sum += Number(el.price)));
    return sum;
  };
  useEffect(() => {
    let temp_sub_total = 0;
    order_item_cards.map(
      (el) => (temp_sub_total += Number(el.total))
      // (temp_sub_total +=
      //   Number(el.item_price) + Number(calc_addon_subtotal(el.addon_items)))
    );
    setsub_total(temp_sub_total);
  }, [order_item_cards]);
  // items - end

  // tags - start
  const [tag_ids, settag_ids] = useState([]);
  const [to_launch, setto_launch] = useState(0);
  // { order_id: "", order_item: '' },
  // tags - end
  // images/notes - start
  const [globaldiscount, setglobaldiscount] = useState(0);
  const [discount_switch_value, setdiscount_switch_value] = useState("");
  const [discount_switch, setdiscount_switch] = useState(false);

  const [images, setimages] = useState(
    Object.keys(data).indexOf("cust_img_note") == -1
      ? [{ img: "", notes: "" }]
      : [{ ...data.cust_img_note }]
  );
  useEffect(() => {
    if (data.file_data && Object.keys(data.file_data).length) {
      // order_item_cards, globaldiscount, discount_switch, images, tag_ids
      setorder_item_cards([...data.file_data.order_item]);
      // setglobaldiscount(Number(data.file_data.globaldiscount));
      setdiscount_switch_value(data.file_data.globaldiscount);
      setdiscount_switch(data.file_data.discount_switch);
      setimages([...data.file_data.images_notes]);
      settag_ids([...data.file_data.tags]);
      if (Object.keys(data.file_data.payment_details).length) {
        // setpayment_details({
        //   failed_cards: [],
        //   payed_as: {},
        //   payed: false,
        // });
        // return;
        setpayment_details({
          failed_cards: data.file_data.payment_details.failed_cards,
          payed_as: data.file_data.payment_details.payed_as,
          payed: data.file_data.payment_details.payed,
        });
        if (data.file_data.payment_details.payed) {
          setadd_order_current_showing("add_order_menu_status");
        }
      }
    }
    setto_launch(1);
  }, [data]);
  useLayoutEffect(() => {
    if (to_launch == 2) {
      putorder(
        {
          id: data.order_id,
          data_to_save: JSON.stringify({
            order_item: [...order_item_cards],
            globaldiscount: discount_switch_value,
            discount_switch: discount_switch,
            images_notes: images,
            tags: [...tag_ids],
            payment_details: payment_details,
            submitted: submit_btn,
          }),
        },
        current_store.user.token,
        (data) => {
          // setpayment_details({
          //   failed_cards: data.file_data.payment_details.failed_cards,
          //   payed_as: data.file_data.payment_details.payed_as,
          //   payed: data.file_data.payment_details.payed,
          // });
        }
      );
    }
    setto_launch(2);
  }, [
    order_item_cards,
    discount_switch_value,
    discount_switch,
    images,
    tag_ids,
    to_launch,
    payment_details,
    submit_btn,
  ]);
  useEffect(() => {
    if (discount_switch) {
      if (sub_total < discount_switch_value) {
        setglobaldiscount(sub_total);
      } else {
        setglobaldiscount(discount_switch_value);
      }
    } else {
      if (discount_switch_value > 100) {
        setglobaldiscount(sub_total);
      } else {
        setglobaldiscount((sub_total / 100) * discount_switch_value);
      }
    }
  }, [discount_switch_value, discount_switch, sub_total]);
  // images/notes - end
  return (
    <div className="add_order">
      <div className="pagetitle">
        <div>Add Order</div>
      </div>
      <div className="addorder">
        <div
          className="refresh_btn"
          onClick={() => {
            getorderbyid(data.order_id, current_store.user.token, (data) => {
              getclient_byid(
                data.data.sql_data.client_id,
                current_store.user.token,
                (data1) => {
                  setdata({
                    order_id: data.data.sql_data.order_id,
                    notes: data.data.sql_data.notes || "",
                    name: data.data.sql_data.client_name,
                    address:
                      // "",
                      data.data.sql_data.address,
                    phone: mobile_convert_display(data.data.sql_data.mobile),
                    payment: data.data.sql_data.last_4,
                    status_order_data: [...data.data.status_logs],
                    order_created: data.data.sql_data.order_date,
                    card_list: data1.data.cards,
                    client_id: data.data.sql_data.client_id,
                    charged: data.data.sql_data.charged,
                    delivered: data.data.sql_data.delivered,
                    cust_img_note: {
                      img: [...data.data.sql_data.images.map((el) => el.url)],
                      notes: data.data.sql_data.notes || "",
                    },
                    discount_code: data.data.sql_data.discount_code || null,
                    client_particulars: data.data.sql_data
                      .client_particulars || {
                      others: "0",
                      laundry: "0",
                      drycleaning: "0",
                    },
                    file_data: {
                      ...(["undefined", ""].indexOf(data.data.file_data) != -1
                        ? {}
                        : JSON.parse(data.data.file_data)),
                    },
                  });
                  setloader(false);
                  navigate("/add-order");
                }
              );
            });
          }}
        >
          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 330.006 330.006"
            style={{
              height: 20,
              width: 20,
              enableBackground: "new 0 0 330.006 330.006;",
            }}
            space="preserve"
          >
            <g>
              <path
                d="M304.302,209.419c-7.594-3.318-16.435,0.148-19.751,7.739c-20.766,47.527-67.69,78.236-119.546,78.236
		c-41.106,0-79.108-19.32-103.445-51.003l51.23,10.19c8.126,1.617,16.021-3.661,17.638-11.786
		c1.616-8.125-3.661-16.022-11.786-17.638l-84.004-16.709c-8.125-1.612-16.021,3.661-17.638,11.786L0.291,304.238
		c-1.616,8.125,3.661,16.022,11.786,17.638c0.988,0.196,1.972,0.291,2.942,0.291c7.01,0,13.276-4.94,14.696-12.077l9.148-45.992
		c29.972,38.123,76.202,61.296,126.142,61.296c63.78,0,121.496-37.77,147.036-96.225
		C315.358,221.579,311.893,212.736,304.302,209.419z"
                fill="#fff"
              />
              <path
                d="M317.929,8.12c-8.125-1.614-16.022,3.661-17.638,11.786l-9.149,45.997C261.169,27.783,214.94,4.611,165.005,4.611
                c-63.779,0-121.495,37.771-147.038,96.224c-3.317,7.591,0.148,16.434,7.739,19.751c1.956,0.854,3.993,1.259,5.999,1.259
                c5.781,0,11.29-3.362,13.753-8.998c20.768-47.527,67.693-78.237,119.548-78.237c41.099,0,79.1,19.316,103.437,50.995
                l-51.228-10.191c-8.127-1.615-16.022,3.66-17.639,11.785c-1.616,8.125,3.66,16.022,11.785,17.639l84.006,16.711
                c0.971,0.193,1.951,0.288,2.927,0.288c2.945,0,5.849-0.868,8.333-2.528c3.308-2.21,5.602-5.644,6.378-9.546l16.709-84.006
                C331.331,17.633,326.054,9.736,317.929,8.12z"
                fill="#fff"
              />
            </g>
          </svg>
        </div>
        <div className="current_data_card">
          <div className="order_date">{data.order_created}</div>
          <div className="data_name">{data.name}</div>
          <div className="data_address">
            {data.address === "no, no, no"
              ? "No address on file"
              : data.address}
          </div>
          <div className="data_items"></div>
          <div className="data_phone">{data.phone}</div>
          <div
            className="data_ccnumber"
            fcolor={data.payment == "" ? "red" : ""}
          >
            {data.payment ? `**** **** ${data.payment}` : "No CC on file"}
          </div>
          {(data.client_particulars?.drycleaning == 1 ||
            data.client_particulars?.laundry == 1 ||
            data.client_particulars?.others == 1) && (
            <div className="data_clientparticulars">
              <div className="particular_title">Client Particulars</div>
              {data.client_particulars.drycleaning == 1 ? (
                <div>Drycleaning</div>
              ) : (
                ""
              )}
              {data.client_particulars.laundry == 1 ? <div>Laundry</div> : ""}
              {data.client_particulars.others == 1 ? <div>Others</div> : ""}
            </div>
          )}
          <div className="card_data_divider"> </div>
          <div className="data_order_items" scrolltype="white">
            {order_item_cards.map((el, i) => (
              <div className="order_item_cards">
                <div className="order_item_name">
                  <div className="item_count">
                    {el.count || 1}
                    <div>-</div>
                  </div>

                  <div className="item_name">
                    {el.item_name.split("%ITEMS_NAME_DIVIDER%").length == 3 ? (
                      <div className="name_part3">
                        {el.item_name
                          .split("%ITEMS_NAME_DIVIDER%")
                          .map((el1, i1) => (
                            <div>{el1}</div>
                          ))}
                      </div>
                    ) : (
                      <div className="name_part2">
                        {el.item_name
                          .split("%ITEMS_NAME_DIVIDER%")
                          .map((el1, i1) => (
                            <div>{el1}</div>
                          ))}
                      </div>
                    )}
                    {el.item_weight != -1 && (
                      <div className="weight_div">{el.item_weight}lb</div>
                    )}
                  </div>
                  <div className="item_price">${el.item_price}</div>
                  {/* {el?.addon_items && el.addon_items.length > 0 && ( */}
                  <div className="item_addon_group">
                    {el.addon_items.map((el1, i) => (
                      <div className="item_addonitem">
                        <div className="addon_list">{i + 1}.</div>
                        <div className="addon_name">
                          <div>{el1.addon_title}</div>
                          <div>{el1.addon_sub}</div>
                        </div>
                        <div className="addon_price">
                          ${el1.price.toFixed(2)}
                        </div>
                      </div>
                    ))}
                    {el.addon_items.length > 0 &&
                    Number(el.discount_value) > 0 ? (
                      <div className="divider_line">
                        {/* {el.discount_value} */}
                      </div>
                    ) : (
                      ""
                    )}
                    {/* start options */}
                    {Number(el.discount_value) > 0 &&
                      el.addon_items.length > 0 && (
                        <div className="card_total">
                          {el.addon_items.length > 0 && (
                            <>
                              <div>SubTotal</div>
                              <div>
                                $
                                {Number(el.total) === 0
                                  ? (
                                      Number(el.item_price) +
                                      Number(
                                        el.addon_items.reduce(
                                          (prev, curr) => prev + curr.price,
                                          0
                                        )
                                      )
                                    ).toFixed(2)
                                  : (
                                      Number(el.total) +
                                      (Number(el.discount_value) > 0
                                        ? Number(el.discount_value)
                                        : 0)
                                    ).toFixed(2)}
                              </div>
                            </>
                          )}
                        </div>
                      )}

                    {Number(el.discount_value) > 0 && (
                      <>
                        <div className="card_discount_total">
                          <div>Discount</div>
                          <div>
                            - $
                            {Number(el.total) === 0
                              ? (
                                  Number(el.item_price) +
                                  Number(
                                    el.addon_items.reduce(
                                      (prev, curr) => prev + curr.price,
                                      0
                                    )
                                  )
                                ).toFixed(2)
                              : Number(el.discount_value).toFixed(2)}
                          </div>
                        </div>
                      </>
                    )}
                    <div className="divider_line"></div>
                    <div className="card_total">
                      <div>Total</div>
                      <div>${el.total}</div>
                    </div>
                    {/* end options */}
                  </div>
                </div>
                <div
                  className={
                    "delete_order_item" +
                    (add_order_current_showing == "add_order_menu_items"
                      ? " show"
                      : " not_show")
                  }
                >
                  <TrashIconBlack
                    white={true}
                    clickhandel={() => {
                      setitem_delete_modal({ i: i, modal_show: true });
                    }}
                  />
                </div>
                {/* <div>test</div> */}
              </div>
            ))}
          </div>
          <div className="data_subtotal">
            <div className="txt1">Subtotal</div>
            <div className="txt2">${Number(sub_total).toFixed(2)}</div>
          </div>
          <div className="data_discount">
            <div className="txt1">Discount</div>
            <div className="txt2">-${Number(globaldiscount).toFixed(2)}</div>
          </div>
          {/* <div className="data_line"></div> */}
          <div className="data_total">
            <div className="txt1">Total</div>
            <div className="txt2">
              ${Number(sub_total - globaldiscount).toFixed(2)}
            </div>
          </div>
        </div>
        <div className="current_data_options">
          <div className="order_menu">
            {"ITEMS TAGS IMAGES STATUS".split(" ").map((el) => (
              <div
                className="order_menu_tabs"
                add_order_menu_active={(
                  `add_order_menu_${el.toLowerCase()}` ==
                  add_order_current_showing
                ).toString()}
                onClick={() => {
                  console.log(data);
                  if (data.charged == "1" && el !== "ITEMS")
                    setadd_order_current_showing(
                      `add_order_menu_${el.toLowerCase()}`
                    );
                  return;
                }}
                // id={`add_order_menu_${el.toLowerCase()}`}
              >
                {el}
              </div>
            ))}
          </div>
          <div className="order_menu_body">
            {add_order_current_showing == "add_order_menu_items" ? (
              <>
                <PricelistAccordian
                  pricelist1={pricelist}
                  pricelist_subsection={pricelist_subsection}
                  order_item_cards={order_item_cards}
                  setorder_item_cards={setorder_item_cards}
                  discountlist_current={discountlist_current}
                />
                <div className="continue_to_tags">
                  <div
                    className="btn"
                    type={order_item_cards.length == 0 ? "type1" : "type2"}
                    onClick={() => {
                      if (order_item_cards.length == 0) return;
                      setadd_order_current_showing("add_order_menu_tags");
                    }}
                  >
                    Continue to Tags
                  </div>
                </div>
              </>
            ) : add_order_current_showing == "add_order_menu_tags" ? (
              <AddOrderTags
                payment_details={payment_details}
                data={data}
                setadd_order_current_showing={setadd_order_current_showing}
                order_item_cards={order_item_cards}
                tag_ids={tag_ids}
                settag_ids={settag_ids}
              />
            ) : add_order_current_showing == "add_order_menu_images" ? (
              <AddOrderImages
                data={data}
                order_item_cards={order_item_cards}
                setadd_order_current_showing={setadd_order_current_showing}
                images={images}
                setimages={setimages}
                globaldiscount={globaldiscount}
                setglobaldiscount={setglobaldiscount}
                discount_switch={discount_switch}
                setdiscount_switch={setdiscount_switch}
                discount_switch_value={discount_switch_value}
                setdiscount_switch_value={setdiscount_switch_value}
                sub_total={sub_total}
                loader={loader}
                setloader={setloader}
                setsubmit_btn={setsubmit_btn}
                discountlist_current={discountlist_current}
              />
            ) : add_order_current_showing == "add_order_menu_status" ? (
              <AddOrderStatus
                data={data}
                total={Number(sub_total - globaldiscount).toFixed(2)}
                payment_details={payment_details}
                setpayment_details={setpayment_details}
                setloader={setloader}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {item_delete_modal.modal_show ? (
        <div className="pricelist_item_delete_modal">
          <div>
            <div className="txt">Confirm Delete?</div>
            <div className="pricelist_item_delete_modal_delete_btn_group">
              <div
                className="btn"
                type="type4"
                onClick={() => {
                  let temp_arr = [...order_item_cards];
                  temp_arr.splice(item_delete_modal.i, 1);
                  let current_item_name =
                    order_item_cards[item_delete_modal.i].item_name;
                  setorder_item_cards([...temp_arr]);
                  settag_ids(
                    tag_ids.filter((el) => el.order_item != current_item_name)
                  );
                  setitem_delete_modal(false);
                }}
              >
                Delete
              </div>
              <div
                className="btn"
                type="type4"
                onClick={() => {
                  setitem_delete_modal({ i: -1, modal_show: false });
                }}
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const comment = () => {
  // let data = {
  //   order_id: "#ORDER12345",
  //   name: "Ken Kent",
  //   address: "247 West 87th Street NY 10024",
  //   phone: "+1 (646) 525-6754",
  //   payment: "7981",
  //   status: 3,
  //   pay_status: 0,
  //   order_created: "2022-04-04",
  //   desc: "",
  //   add_order_data: {
  //     order_item: [],
  //     discount: "0",
  //   },
  //   tags_order_data: [],
  //   status_order_data: ['04-04-2022 13:00', '04-04-2022 14:00', '04-04-2022 15:00', '04-04-2022 16:00', '04-04-2022 17:00']
  // };
};
