import React, { useEffect, useState } from "react";
import "./addordertagsStyle.scss";

import tag_id_add_img from "../../../img/miscellaneous/close_square_add.png";
import tag_delete_icon from "../../../img/miscellaneous/trash.png";
import { ArrowDown, Receipt } from "../../Common/Images/Images";
import { useDispatch, useSelector } from "react-redux";
let convert_base = [
  ..."0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ".split(""),
];
console.log(convert_base);

export const AddOrderTags = ({
  payment_details,
  data,
  order_item_cards,
  tag_ids,
  settag_ids,
  setadd_order_current_showing,
  // select_item,
  // setselect_item,
  // tag_id_value,
  // settag_id_value,
}) => {
  const current_store = useSelector((state) => state);
  const [printer_ip, setprinter_ip] = useState("");
  const handel_change_tagid = (current, i) => {
    let temp_tag_ids = [...tag_ids];
    let temp_data = {
      order_id: current,
      order_item: tag_ids[i].order_item,
    };
    temp_tag_ids[i] = temp_data;
    settag_ids([...temp_tag_ids]);
  };
  const handel_change_selectitem = (current, i) => {
    let temp_tag_ids = [...tag_ids];
    let temp_data = {
      order_id: tag_ids[i].order_id,
      order_item: current,
    };
    temp_tag_ids[i] = temp_data;
    settag_ids([...temp_tag_ids]);
  };
  const print_Data = (tag_data) => {
    console.log("started");

    var printer = null;
    var ePosDev = new window.epson.ePOSDevice();
    ePosDev.connect(printer_ip, 8008, cbConnect);

    function cbConnect(data) {
      console.log("started connection");
      if (data == "OK" || data == "SSL_CONNECT_OK") {
        ePosDev.createDevice(
          "local_printer",
          ePosDev.DEVICE_TYPE_PRINTER,
          { crypto: false, buffer: false },
          cbCreateDevice_printer
        );
      } else {
        console.log(data);
      }
    }

    function cbCreateDevice_printer(devobj, retcode) {
      console.log("checking connection");
      if (retcode == "OK") {
        printer = devobj;
        printer.timeout = 60000;
        printer.onreceive = function (res) {
          console.log(res);
          console.log(res.success);
        };
        printer.oncoveropen = function () {
          console.log("coveropen");
        };
        console.log(printer);
        console.log("started printing");
        print();
      } else {
        console.log(retcode);
      }
    }

    function print() {
      printer.addLayout(printer.LAYOUT_RECEIPT, 800, 0, 0, 0, 0, 0);
      printer.addTextLineSpace(20);
      printer.addText("\n");
      printer.addTextSmooth(true);
      printer.addTextDouble(true, true);
      printer.addTextStyle(false, false, true, printer.COLOR_1);
      printer.addText(`${data.order_id}\n`);
      printer.addTextLineSpace(10);
      printer.addTextSize(1, 1);
      printer.addFeedUnit(15);
      printer.addText(`Tag ID - ${tag_data?.order_id}\n`);
      printer.addFeedUnit(15);
      printer.addText(`Item - `);
      printer.addText(
        tag_data.order_item.split("%ITEMS_NAME_DIVIDER%").join(" -> ") + "\n"
      );
      printer.addFeedUnit(30);
      printer.addBarcode(
        // tag_data?.order_id || "Tag_Err",
        data.order_id +
          "-" +
          tag_data.order_id
            .split("")
            .map((el) => ("00" + convert_base.indexOf(el).toString()).slice(-2))
            .join(""),
        printer.BARCODE_CODE39,
        // printer.HRI_NONE,
        printer.HRI_BELOW,
        // printer.FONT_A,
        printer.FONT_C,
        2,
        64
      );
      printer.addTextLineSpace(100);
      printer.addText("\n");
      printer.addCut(printer.CUT_FEED);
      printer.send();
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    let local_token = localStorage.getItem("jc_printer_ip");
    // setinp(local_token);
    setprinter_ip(local_token);
    dispatch({
      type: "put_default",
      payload: { printer_ip: local_token },
    });
  }, []);

  return (
    <div className="addordertags">
      <div className="tag_order_id">
        <div className="tag_title">Order ID</div>
        <input type="text" value={data.order_id} readOnly />
      </div>
      <div className="create_tag_ids">
        {tag_ids.map((el, i) => (
          <div className="tag_id_body">
            <div>
              <div className="tag_title">Tag ID #{i + 1}</div>
              <input
                placeholder={"#Order_Tag" + (i + 1)}
                type="text"
                value={el.order_id}
                onChange={(e) => {
                  // if (e.target.value.length > 4) {
                  //   return;
                  // }
                  handel_change_tagid(e.target.value, i);
                }}
              />
            </div>
            <div>
              <div className="tag_title">Select Item</div>
              <div
                className="tag_cust_select"
                onClick={() => {
                  let tag_id_item_dropdown = document.getElementById(
                    "tag_id_item_dropdown" + i
                  );
                  let tag_id_item_dropdown_arr =
                    document.getElementsByClassName("tag_id_item_dropdown");
                  [...tag_id_item_dropdown_arr].map((el, i1) => {
                    if (i1 == i) return;
                    el.style.height = "";
                    el.setAttribute("active", "false");
                  });

                  tag_id_item_dropdown.setAttribute(
                    "active",
                    (tag_id_item_dropdown.style.height == "").toString()
                  );
                  tag_id_item_dropdown.style.height =
                    tag_id_item_dropdown.style.height == ""
                      ? `${order_item_cards.length * 55}px`
                      : "";
                }}
              >
                {el.order_item.split("%ITEMS_NAME_DIVIDER%").length == 3 ? (
                  <div className="tag_id_item_part3">
                    {el.order_item.split("%ITEMS_NAME_DIVIDER%").map((el) => (
                      <div>{el}</div>
                    ))}
                  </div>
                ) : el.order_item.split("%ITEMS_NAME_DIVIDER%").length == 2 ? (
                  <div className="tag_id_item_part2">
                    {el.order_item.split("%ITEMS_NAME_DIVIDER%").map((el) => (
                      <div>{el}</div>
                    ))}
                  </div>
                ) : (
                  <div className="tag_id_item_part2">{el.order_item}</div>
                )}
                <ArrowDown type="sm" />
                <div
                  className="tag_id_item_dropdown"
                  id={"tag_id_item_dropdown" + i}
                  active="false"
                >
                  {order_item_cards.map((el) => (
                    <div
                      onClick={() => {
                        handel_change_selectitem(el.item_name, i);
                        // setselect_item(el.item_name);
                      }}
                    >
                      {el.item_name.split("%ITEMS_NAME_DIVIDER%").length ==
                      3 ? (
                        <div className="tag_id_item_part3">
                          {el.item_name
                            .split("%ITEMS_NAME_DIVIDER%")
                            .map((el1) => (
                              <div>{el1}</div>
                            ))}
                        </div>
                      ) : (
                        <div className="tag_id_item_part2">
                          {el.item_name
                            .split("%ITEMS_NAME_DIVIDER%")
                            .map((el1) => (
                              <div>{el1}</div>
                            ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              {/* <input type="text" value={el.order_item} readOnly /> */}
            </div>
            <div className="tag_delete_btn">
              <img
                src={tag_delete_icon}
                onClick={() => {
                  let temp_tag_ids = [...tag_ids];
                  temp_tag_ids.splice(i, 1);
                  settag_ids([...temp_tag_ids]);
                }}
              />
            </div>
            {tag_ids[i]?.order_id && (
              <div className="tag_delete_btn tag_print_btn">
                <Receipt
                  clickhandel={() => {
                    console.log(tag_ids[i]);
                    print_Data(tag_ids[i]);
                  }}
                />
              </div>
            )}
          </div>
        ))}
        <div className="tag_id_add_img">
          <img
            src={tag_id_add_img}
            onClick={() => {
              settag_ids([
                ...tag_ids,
                { order_id: "", order_item: order_item_cards[0].item_name },
              ]);
            }}
          />
        </div>
      </div>
      {/* <div className="tag_id_body ">
        <div>
          <div className="tag_title">Tag ID #{tag_ids.length + 1}</div>
          <input
            type="text"
            value={tag_id_value}
            onChange={(e) => {
              settag_id_value(e.target.value);
            }}
          />
        </div>
        <div>
          <div className="tag_title">Select Item</div>
          <div
            className="tag_cust_select"
            onClick={() => {
              let tag_id_item_dropdown = document.getElementsByClassName(
                "tag_id_item_dropdown"
              )[0];
              tag_id_item_dropdown.setAttribute(
                "active",
                (tag_id_item_dropdown.style.height == "").toString()
              );
              tag_id_item_dropdown.style.height =
                tag_id_item_dropdown.style.height == ""
                  ? `${order_item_cards.length * 55}px`
                  : "";
            }}
          >
            {select_item.split("-").length == 3 ? (
              <div className="tag_id_item_part3">
                {select_item.split("-").map((el) => (
                  <div>{el}</div>
                ))}
              </div>
            ) : select_item.split("-").length == 2 ? (
              <div className="tag_id_item_part2">
                {select_item.split("-").map((el) => (
                  <div>{el}</div>
                ))}
              </div>
            ) : (
              select_item
            )}
            <div className="tag_id_item_dropdown" active="false">
              {order_item_cards.map((el) => (
                <div
                  onClick={() => {
                    setselect_item(el.item_name);
                  }}
                >
                  {el.item_name.split("-").length == 3 ? (
                    <div className="tag_id_item_part3">
                      {el.item_name.split("-").map((el1) => (
                        <div>{el1}</div>
                      ))}
                    </div>
                  ) : (
                    <div className="tag_id_item_part2">
                      {el.item_name.split("-").map((el1) => (
                        <div>{el1}</div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
       
      </div> */}
      <div className="tags_btn_group">
        <div
          style={
            data.charged == "1"
              ? {
                  visibility: "hidden",
                }
              : {}
          }
          className="btn"
          type="type2"
          onClick={() =>
            data.charged !== "1"
              ? setadd_order_current_showing("add_order_menu_items")
              : ""
          }
        >
          Add another Item1
        </div>

        <div
          className="btn"
          type="type2"
          style={{ margin: "0 0 0 auto" }}
          onClick={() => {
            settag_ids([
              ...tag_ids.filter(
                (el) =>
                  el.order_id != "" && el.order_item != "Select One..." && el
              ),
            ]);
            // settag_ids([...tag_ids.filter=>])
            setadd_order_current_showing("add_order_menu_images");
          }}
        >
          Continue To Images
        </div>
      </div>
    </div>
  );
};
